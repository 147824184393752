import axios from 'axios';

export const BASE_URL = 'https://cv-makerr-7f7c7ad2e5a4.herokuapp.com/';

/**
 * NB: CHANGE URL BEFORE DEPLOYING TO POINT TO NEW BACKEND URL
 */
export const axiosFactory = () => {
  if (localStorage.getItem('token')) {
    return axios.create({
      baseURL: BASE_URL,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  }
  return axios.create({ baseURL: BASE_URL });
};
