import { Link } from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi';
import { useHistory } from 'react-router';
import { Avatar, Box, HStack, Image, Text } from '@chakra-ui/react';

import UI from '../assets/images/image.png';

const CvHeader = () => {
  const router = useHistory();
  const data = JSON.parse(localStorage.getItem('user_data'));

  const handleSignout = () => {
    localStorage.removeItem('token');

    router.push('/login/');
  };
  return (
    <Box background="#267D77">
      <HStack
        p={4}
        as="nav"
        width="100%"
        margin="auto"
        align="center"
        justifyContent="space-between"
      >
        <Link to="/">
          <Image src={UI} boxSize="2.5rem" alt="university of ibadan" />
        </Link>
        <Text color="white" textTransform="capitalize">
          {data.lastname} {data.firstname}
        </Text>
        <HStack spacing={4}>
          <Avatar name={data.lastname + ' ' + data.firstname} />
          <HStack onClick={() => handleSignout()}>
            <Text color="white">Logout</Text>
            <FiLogOut color="white" />
          </HStack>
        </HStack>
      </HStack>
    </Box>
  );
};

export default CvHeader;
