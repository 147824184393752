import {
  Box,
  Flex,
  Icon,
  Text,
  Input,
  Stack,
  Button,
  HStack,
  Divider,
  Heading,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { ScholarshipContext } from '../context';
import DeleteSection from '../preview/generic/delete';
import { IoIosAddCircleOutline } from 'react-icons/io';
import isEmpty from '../utils/isEmpty';
import { Field } from '../utils/special';

export const ScholarshipPreview = () => {
  const { getter, scholarship } = useContext(ScholarshipContext);
  return (
    <div className="cv-section">
      <div
        style={{
          display: 'flex',
        }}
      >
        <p>VI.</p>
        <div className="two-column" style={{ paddingLeft: '24px' }}>
          <p className="underline">
            <Field>
              Scholarships, Fellowships and Prizes (with dates) in respect of
              Undergraduate and
            </Field>
            <br /> <Field>Postgraduate work only):</Field>
          </p>{' '}
          <p>{isEmpty(scholarship) && 'Nil'}</p>
        </div>
      </div>
      <ol className="lower-roman">
        {!isEmpty(scholarship) &&
          scholarship.map((each, index) => (
            <li
              key={index}
              style={{
                marginLeft: '34px',
              }}
            >
              <p> </p>
              <div className="two-column" style={{ marginLeft: '4px' }}>
                <p>
                  <span>{getter('scholarship_description', index)}</span>
                </p>{' '}
                <p>
                  <span>{getter('date_of_award', index)}</span>
                </p>
              </div>
            </li>
          ))}
      </ol>
    </div>
  );
};

const Scholarship = () => {
  // eslint-disable-next-line
  const [data, setData] = React.useState({});

  const { getter, setter, scholarship, addNew, remove, removeAll } =
    useContext(ScholarshipContext);

  const handleInputChangeForIndex = index => e => {
    setter(e.target.name, e.target.value, index);
  };

  return (
    <>
      <Flex
        mb={8}
        px={12}
        width="50%"
        height="100%"
        align="center"
        maxWidth="500px"
        direction="column"
        justify="space-between"
      >
        <Box
          p={6}
          mb={10}
          width="530px"
          boxShadow="0px 7px 50px rgba(232, 242, 241, 0.8)"
        >
          <Box>
            <HStack align="flex-start" justify="space-between">
              <Box>
                <Heading fontSize="xl" color="teal.700" fontWeight="400">
                  Scholarships
                </Heading>
                <Box
                  mt={3}
                  width="80px"
                  h="3px"
                  backgroundColor="#4ECDC4"
                ></Box>
              </Box>
              <Text
                textDecoration="underline"
                color="#1A535C"
                onClick={() => removeAll()}
              >
                clear fields
              </Text>
            </HStack>
          </Box>

          <Stack spacing={6} py={4}>
            {scholarship.map((each, index) => (
              <>
                <Divider />
                <DeleteSection onClick={() => remove(index)} />
                <Input
                  py={6}
                  type="text"
                  name="scholarship_description"
                  aria-label="Scholarshhip Description"
                  value={getter('scholarship_description', index)}
                  onChange={handleInputChangeForIndex(index)}
                  placeholder="Scholarship Description"
                />
                <HStack>
                  <Input
                    py={6}
                    type="date"
                    name="date_of_award"
                    aria-label="Date of Award"
                    value={getter('date_of_award', index)}
                    onChange={handleInputChangeForIndex(index)}
                    placeholder="Date of Award"
                  />
                </HStack>
              </>
            ))}
            <Button
              py={6}
              px={6}
              fontWeight={400}
              onClick={addNew}
              variant="outline"
              colorScheme="teal"
              borderStyle="dashed"
            >
              <Icon as={IoIosAddCircleOutline} w={6} h={6} marginRight={3} />
              Add another Qualification
            </Button>
          </Stack>
        </Box>
      </Flex>
    </>
  );
};

export default Scholarship;
