
// DEAR FUTURE MAINTAINER, this is a work or art. if nothing do am no touch am 🙏🏽
export const useFormListContextHandlerFactory = (prefix, globalState) => {
  const [state, setState] = globalState;

  const setter = (attribute, value, index) => {
    let stateClone = state[prefix];
    if (!stateClone) {
      stateClone = [];
    }

    let fetchedItem = stateClone[index];
    if (!fetchedItem) fetchedItem = {};

    fetchedItem[`${prefix}_${attribute}`] = value;

    stateClone[index] = fetchedItem;
    setState(s => ({ ...s, [prefix]: [...stateClone] }));
  };

  const getter = (attribute, index) => {
    const stateClone = state[prefix];
    if (!stateClone) return '';

    const fetchedItem = stateClone[index];

    if (!fetchedItem) return '';

    return fetchedItem[`${prefix}_${attribute}`];
  };

  const addNewItem = () => {
    if (!state[prefix]) {
      setState(s => ({ ...s, [prefix]: [{}] }));
      return;
    }
    setState(s => ({ ...s, [prefix]: [...s[prefix], {}] }));
  };

  const removeItem = index => {
    const stateClone = state[prefix];
    if (stateClone.length <= 1 && Object.keys(stateClone[0]).length !== 0) {
      setState(s => ({ ...s, [prefix]: [{}] }));
      return;
    }
    const mutatedState = stateClone.filter((each, i) => i !== index);
    setState(s => ({ ...s, [prefix]: mutatedState }));
  };

  const removeAll = () => {
    setState(s => ({ ...s, [prefix]: [{}] }));
  };

  return {
    state: state[prefix] || [{}],
    getter,
    setter,
    addNewItem,
    removeItem,
    removeAll,
  };
};

// TODO: work on this
export const useSingleFormContextHandlerFactory = (prefix, globalState) => {
  const [state, setState] = globalState;

  const setter = (attribute, value) => {
    let stateClone = state[prefix];
    if (!stateClone) {
      stateClone = {};
    }
    stateClone[`${prefix}_${attribute}`] = value;
    setState(s => ({ ...s, [prefix]: { ...stateClone } }));
  };

  const getter = attribute => {
    const obj = state[prefix];
    if (!obj) return '';
    return obj[`${prefix}_${attribute}`] || '';
  };

  return {
    getter,
    setter,
    state: state[prefix] || {},
  };
};
