import React from 'react';
import { Box, Text, Image, VStack, Button, Heading } from '@chakra-ui/react';

import BG from '../assets/images/bg.png';
// import UI from '../assets/images/image.png';
// import FS from '../assets/images/image.png';
import Preview from '../assets/images/desktop.png';
// import Cut from '../assets/svgs/section-cut.svg';

import Nav from './Nav';

// import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <Box
      as="section"
      minHeight="100vh"
      backgroundImage={BG}
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
    >
      <Nav />

      <VStack spacing={12} minHeight="calc(100vh - 72px)">
        <VStack
          px={4}
          spacing={8}
          width="100%"
          color="white"
          margin="auto"
          maxWidth="932px"
          paddingTop="10%"
          textAlign="center"
        >
          <Heading fontSize={{ base: '3xl', md: '5xl' }}>
            BUILD ADEQUATE CVs REQUIRED FOR PROMOTION
          </Heading>
          <Text
            width="100%"
            color="white"
            margin="auto"
            maxWidth="560px"
            fontSize={{ base: 'md', md: 'xl' }}
          >
            Create an academic CV in a matter of minutes that will help you get
            that promotion you want
          </Text>
          <Button
            as="a"
            href="/signup"
            px="1.875rem"
            py="1.125rem"
            height="100%"
            color="#1A535C"
            fontSize="1.125rem"
            borderRadius="none"
            backgroundColor="#FFD81E"
            _hover={{
              backgroundColor: '#FFE877',
            }}
          >
            Create CV now
          </Button>
        </VStack>
        <Image
          px={4}
          zIndex="2"
          width="100%"
          margin="auto"
          src={Preview}
          maxWidth="1280px"
          alt="project preview"
          display={{ base: 'none', md: 'block' }}
        />
      </VStack>
      {/* <Box position="absolute" zIndex="0" bottom="0" left="0" right="0">
        <Image
          px={4}
          mx={-8}
          src={Cut}
          zIndex="0"
          mt={'-200px'}
          width="100%"
          objectFit="contain"
          margin="auto"
          alt="project preview"
        />
      </Box> */}
    </Box>
  );
};

export default Hero;
