import React from 'react';
import {
  Box,
  Flex,
  Link,
  Text,
  Spacer,
  Button,
  HStack,
  Circle,
  VStack,
  Heading,
  Divider,
  SimpleGrid,
} from '@chakra-ui/react';

const Features = () => {
  return (
    <Box
      as="section"
      minH="200px"
      paddingBottom={'200px'}
      bgColor="#267D77"
      marginTop={{ base: 0, md: '-220px' }}
      paddingTop={{ base: '5rem', md: '300px' }}
    >
      <Box as="section">
        <VStack>
          <Heading color="white" marginBottom={4}>
            Some Key Features
          </Heading>
          <Divider width="150px" height="5px" backgroundColor=" #4ECDC4" />
        </VStack>
        <SimpleGrid
          px={8}
          marginTop="8rem"
          spacing={{ base: '6rem', md: '10rem' }}
        >
          <Flex
            width="100%"
            margin="auto"
            maxWidth="1080px"
            alignItems="center"
            justifyContent="space-between"
            flexDirection={{ base: 'column', md: 'row' }}
          >
            <Divider
              opacity="1"
              border="none"
              width="10px"
              height="150px"
              orientation="vertical"
              backgroundColor=" #FFD81E"
              display={{ base: 'none', md: 'block' }}
            />
            <Spacer />
            <Box width="100%" maxWidth="400px" mb={{ base: 12, md: 0 }}>
              <Heading as="h3" size="lg" color="white">
                Proper academic CV rules
              </Heading>
              <Text size="md" lineHeight="1.6" mt="1.875rem" color="white">
                The little details that are obligatory in writing a CV good
                enough for promotions are put in place to ensure the committee
                receiving your CV is more than impressed.
              </Text>
            </Box>
            <Spacer />
            <Box
              width="100%"
              maxWidth="400px"
              height="300px"
              backgroundColor="white"
            ></Box>
          </Flex>
          <Flex
            width="100%"
            margin="auto"
            maxWidth="1080px"
            alignItems="center"
            justifyContent="space-between"
            flexDirection={{ base: 'column-reverse', md: 'row' }}
          >
            <Box
              width="100%"
              maxWidth="400px"
              height="300px"
              backgroundColor="white"
            ></Box>
            <Spacer />
            <Box width="100%" maxWidth="400px" mb={{ base: 12, md: 0 }}>
              <Heading as="h3" size="lg" color="white">
                The preview function
              </Heading>
              <Text size="md" lineHeight="1.6" mt="1.875rem" color="white">
                A feature that allows you see how your final presentable CV will
                look like while filling in your details. It can help you detect
                errors and mistakes that are hard to spot.
              </Text>
            </Box>
            <Spacer />
            <Divider
              opacity="1"
              width="10px"
              height="150px"
              orientation="vertical"
              backgroundColor=" #FFD81E"
              display={{ base: 'none', md: 'block' }}
            />
          </Flex>
          <Flex
            width="100%"
            margin="auto"
            maxWidth="1080px"
            alignItems="center"
            justifyContent="space-between"
            flexDirection={{ base: 'column', md: 'row' }}
          >
            <Divider
              opacity="1"
              width="10px"
              height="150px"
              orientation="vertical"
              backgroundColor=" #FFD81E"
              display={{ base: 'none', md: 'block' }}
            />
            <Spacer />
            <Box width="100%" maxWidth="400px" mb={{ base: 12, md: 0 }}>
              <Heading as="h3" size="lg" color="white">
                Saving for later
              </Heading>
              <Text mt="1.875rem" size="md" lineHeight="1.6" color="white">
                You might not be chanced to complete writing your CV at once,
                don't worry about that. You can save your progress to continue
                the next time you login without losing any of your previous
                details or information that you filled in.
              </Text>
            </Box>
            <Spacer />
            <Box
              width="100%"
              maxWidth="400px"
              height="300px"
              backgroundColor="white"
            ></Box>
          </Flex>
        </SimpleGrid>
      </Box>

      <VStack px={8} py={16} my="8rem" spacing={12} backgroundColor="#1E625D">
        <Heading maxWidth="800px" textAlign="center" color="white">
          Ready to make that CV that will enable you get that promotion?
        </Heading>
        <Button
          as="a"
          href="/signup"
          px="1.875rem"
          py="1.125rem"
          height="100%"
          color="#1E625D"
          fontSize="1.125rem"
          borderRadius="none"
          backgroundColor="#FFD81E"
          _hover={{
            backgroundColor: '#FFE877',
          }}
        >
          Get Started Now
        </Button>
      </VStack>

      <Box as="section">
        <VStack>
          <Heading color="white" textAlign="center" marginBottom={4}>
            Fast and easy process to build your CV
          </Heading>
          <Divider width="150px" height="5px" backgroundColor=" #4ECDC4" />
        </VStack>
        <SimpleGrid
          px={8}
          color="white"
          marginTop="8rem"
          spacing={{ base: '6rem', md: '10rem' }}
        >
          <HStack
            width="100%"
            margin="auto"
            spacing="30px"
            maxWidth="1080px"
            alignItems="flex-start"
          >
            <Circle width="3.75rem" height="3.75rem" backgroundColor="#029B90">
              <Text fontSize="2xl">1</Text>
            </Circle>
            <Box
              px="2.5rem"
              width="100%"
              py="3.125rem"
              maxWidth="500px"
              borderRadius="xl"
              backgroundColor="#029B90"
            >
              <Heading fontSize="26px">SIGN UP</Heading>
              <Text marginTop={5} fontSize="1.125rem">
                Firstly, you have to register if this is your first time using
                this platform. You can do that by clicking{' '}
                <Link href="/signup" color="#FFD81E">
                  here
                </Link>
                .
              </Text>
            </Box>
          </HStack>
          <HStack
            width="100%"
            margin="auto"
            spacing="30px"
            maxWidth="1080px"
            alignItems="flex-start"
          >
            <Circle
              width="3.75rem"
              height="3.75rem"
              marginLeft="auto"
              backgroundColor="#029B90"
            >
              <Text fontSize="2xl">2</Text>
            </Circle>
            <Box
              px="2.5rem"
              width="100%"
              py="3.125rem"
              maxWidth="500px"
              borderRadius="xl"
              backgroundColor="#029B90"
            >
              <Heading fontSize="26px">CREATING A PROFILE</Heading>
              <Text marginTop={5} fontSize="1.125rem">
                This involves more of your personal details, like your biodata.
              </Text>
            </Box>
          </HStack>
          <HStack
            width="100%"
            margin="auto"
            spacing="30px"
            maxWidth="1080px"
            alignItems="flex-start"
          >
            <Circle width="3.75rem" height="3.75rem" backgroundColor="#029B90">
              <Text fontSize="2xl">3</Text>
            </Circle>
            <Box
              px="2.5rem"
              width="100%"
              py="3.125rem"
              maxWidth="500px"
              borderRadius="xl"
              backgroundColor="#029B90"
            >
              <Heading fontSize="26px">CAREER INFORMATION</Heading>
              <Text marginTop={5} fontSize="1.125rem">
                This is the final step. It is the part where you fill in
                everything necessary that should be on a CV.
              </Text>
            </Box>
          </HStack>
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default Features;
