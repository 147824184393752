import { Input, Tr, Td } from '@chakra-ui/react';
import React, { useState } from 'react';
import Select from 'react-select';

const CalculatorRow = ({
  index,
  updateRowData,
  rowData,
  setGlobalWeightedScore,
  globalWeightedScore,
}) => {
  const options = [
    { value: '3', label: 'Book' },
    { value: '5', label: 'Journal / Article' },
    { value: '2', label: 'Refereed Conference Proceeding' },
  ];

  const initialState = {
    score: 0,
    position: 0,
    maxScore: 0,
    id: index + 1,
    noOfAuthors: 0,
    contribution: 0,
    weightedScore: 0,
    publicationType: '',
  };

  const [data, setData] = useState(rowData[index] || initialState);

  React.useEffect(() => {
    const weightedScore = data.score * (data.contribution / 100).toFixed(2);

    setGlobalWeightedScore(state => ({ ...state, [index]: weightedScore }));

    updateRowData(data, index);
  }, [data]);

  return (
    <Tr>
      <Td>{data.id}</Td>
      <Td width="100%">
        <Select
          onChange={event => {
            setData(prevState => ({
              ...data,
              maxScore: event.value,
              publicationType: event.label,
            }));
          }}
          options={options}
          styles={{
            control: styles => ({
              ...styles,
              width: '100%',
              height: '50px',
              marginTop: '0',
              overflow: 'hidden',
            }),
            dropdownIndicator: styles => ({
              padding: '0.5rem',
            }),
            container: styles => ({
              ...styles,
              padding: '0',
              width: '100%',
              height: '100%',
            }),
            option: styles => ({
              ...styles,
              paddingLeft: '1rem',
            }),
          }}
        />
      </Td>
      <Td>
        <Input
          textAlign="center"
          py={6}
          background="transparent"
          type="number"
          aria-label="Number of Authors"
          onChange={event => {
            setData(prevState => ({
              ...data,
              noOfAuthors: event.target.value,
            }));
          }}
        />
      </Td>
      <Td>
        <Input
          textAlign="center"
          py={6}
          max={data.noOfAuthors}
          background="transparent"
          type="number"
          value={data.position}
          aria-label="Position"
          onChange={event => {
            setData(prevState => ({
              ...data,
              position: event.target.value,
            }));
          }}
        />
      </Td>

      <Td>
        <Input
          // border="none"
          py={6}
          background="transparent"
          min={0}
          max={100}
          type="number"
          textAlign="center"
          value={data.contribution}
          aria-label="% Contribution"
          onChange={event => {
            setData(prevState => ({
              ...data,
              contribution: event.target.value,
            }));
          }}
        />
      </Td>
      <Td>
        <Input
          border="none"
          py={6}
          disabled
          background="transparent"
          type="number"
          value={data.contribution / 100}
          aria-label="Contribution in Fraction"
        />
      </Td>
      <Td>
        <Input
          border="none"
          py={6}
          background="transparent"
          disabled
          value={data.maxScore}
          type="text"
          aria-label="Maximum Score Obtainable"
        />
      </Td>
      <Td>
        <Input
          py={6}
          background="transparent"
          type="number"
          value={data.score}
          textAlign="center"
          max={data.selected}
          onChange={event => {
            setData(prevState => ({ ...data, score: event.target.value }));
          }}
          aria-label="Score Awarded"
        />
      </Td>

      <Td isNumeric>{globalWeightedScore[index]}</Td>
    </Tr>
  );
};

export default CalculatorRow;
