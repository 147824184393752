import {
  Box,
  Flex,
  Icon,
  Text,
  Input,
  Stack,
  Button,
  HStack,
  Divider,
  Heading,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import DeleteSection from '../preview/generic/delete';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { ProfessionalQualificationsContext } from '../context';
import isEmpty from '../utils/isEmpty';
import { Field } from '../utils/special';

export const ProfessionalQualificationsPreview = () => {
  const { getter, professional } = useContext(
    ProfessionalQualificationsContext
  );

  return (
    <div>
      <div className="cv-section">
        <div
          style={{
            display: 'flex',
          }}
        >
          <p>V.</p>
          <div className="two-column" style={{ paddingLeft: '26px' }}>
            <p className="underline">
              <Field>Professional Qualifications and Diplomas (with dates):</Field>
            </p>{' '}
            <p>{isEmpty(professional) && 'Nil'}</p>
          </div>
        </div>
        <ol className="lower-roman">
          {!isEmpty(professional) &&
            professional.map((each, index) => (
              <li
                key={index}
                style={{
                  marginLeft: '34px',
                }}
              >
                <p> </p>
                <div className="two-column" style={{ marginLeft: '4px' }}>
                  <p>
                    {getter('degree', index)},{' '}
                    {getter('granting_bodies', index)}, {getter('city', index)},{' '}
                    {getter('country', index)}
                  </p>{' '}
                  <p>
                    <span>{getter('date_granted', index)}</span>
                  </p>
                </div>
              </li>
            ))}
        </ol>
      </div>
    </div>
  );
};

const Professional = () => {
  // eslint-disable-next-line
  const { getter, setter, professional, addNew, remove, removeAll } =
    React.useContext(ProfessionalQualificationsContext);

  const handleInputChangeForIndex = index => e => {
    setter(e.target.name, e.target.value, index);
  };

  return (
    <>
      <Flex
        mb={8}
        px={12}
        width="50%"
        height="100%"
        align="center"
        maxWidth="500px"
        direction="column"
        justify="space-between"
      >
        <Box
          p={6}
          mb={10}
          width="530px"
          boxShadow="0px 7px 50px rgba(232, 242, 241, 0.8)"
        >
          <Box>
            <HStack align="flex-start" justify="space-between">
              <Box>
                <Heading fontSize="xl" color="teal.700" fontWeight="400">
                  Professional Qualifications and Diplomas
                </Heading>
                <Box
                  mt={3}
                  width="80px"
                  h="3px"
                  backgroundColor="#4ECDC4"
                ></Box>
              </Box>
              <Text
                textDecoration="underline"
                color="#1A535C"
                onClick={() => removeAll()}
              >
                clear fields
              </Text>
            </HStack>
          </Box>

          {professional.map((each, index) => (
            <Stack spacing={6} py={4}>
              <Divider />
              <DeleteSection onClick={() => remove(index)} />
              <Input
                py={6}
                name="granting_bodies"
                value={getter('granting_bodies', index)}
                onChange={handleInputChangeForIndex(index)}
                type="text"
                aria-label="Granting Bodies (Institutions)"
                placeholder="Granting Bodies (Institutions)"
              />
              <Input
                py={6}
                name="degree"
                value={getter('degree', index)}
                onChange={handleInputChangeForIndex(index)}
                type="text"
                aria-label="Name of Certification"
                placeholder="Name of Certification"
              />
              <Input
                py={6}
                name="date_granted"
                value={getter('date_granted', index)}
                onChange={handleInputChangeForIndex(index)}
                type="text"
                aria-label="Date granted"
                placeholder="Date granted"
              />
              <HStack>
                <Input
                  py={6}
                  type="text"
                  name="city"
                  aria-label="City"
                  placeholder="City"
                  value={getter('city', index)}
                  onChange={handleInputChangeForIndex(index)}
                />

                <Input
                  py={6}
                  type="text"
                  name="country"
                  aria-label="Country"
                  placeholder="Country"
                  value={getter('country', index)}
                  onChange={handleInputChangeForIndex(index)}
                />
              </HStack>
            </Stack>
          ))}
          <Button
            py={6}
            px={6}
            fontWeight={400}
            onClick={addNew}
            isFullWidth
            variant="outline"
            colorScheme="teal"
            borderStyle="dashed"
          >
            <Icon as={IoIosAddCircleOutline} w={6} h={6} marginRight={3} />
            Add another Qualification
          </Button>
        </Box>
      </Flex>
    </>
  );
};

export default Professional;
