import debounce from 'lodash.debounce';
import { useHistory } from 'react-router';
import { useCallback, useEffect, useState } from 'react';
import { Box, Flex, Grid, HStack } from '@chakra-ui/react';

import { AuthGuard } from '../../utils/guard';
import CvHeader from '../../components/CvHeader';
import { Loader } from '../../components/Loader';
import { axiosFactory } from '../../utils/axios';
import { useGetHook, usePostHook } from '../../utils/request';
import {
  BooksContext,
  ArticleContext,
  ResearchContext,
  TeachingContext,
  MonographContext,
  TechnicalContext,
  MajorConfContext,
  FullScreenContext,
  ConferencesContext,
  ScholarshipContext,
  BookChaptersContext,
  BooksAcceptedContext,
  EditorialWorkContext,
  ResearchFocusContext,
  WorkExperienceContext,
  PersonalDetailsContext,
  RevisedChaptersContext,
  ArticlesAcceptedContext,
  PatentCopyrightsContext,
  HonourDistinctionContext,
  UniversityEducationContext,
  BookChaptersAcceptedContext,
  AcademicQualificationContext,
  ProfessionalQualificationsContext,
  PositionSoughtContext,
} from './context/index';
import Books from './forms/Books';
import Articles from './forms/Articles';
import Research from './forms/Research';
import Monograph from './forms/Monograph';
import Technical from './forms/Technical';
import Scholarship from './forms/Scholarship';
import BookChapters from './forms/BookChapters';
import Professional from './forms/Professional';
import MajorConf from './forms/MajorConferences';
import ResearchFocus from './forms/ResarchFocus';
import BooksAccepted from './forms/BooksAccepted';
import EditorialWork from './forms/EditorialWork';
import Teaching from './forms/TeachingExperience';
import WorkExperience from './forms/WorkExperience';
import PersonalDetails from './forms/PersonalDetails';
import RevisedChapters from './forms/RevisedChapters';
import ArticlesAccepted from './forms/ArticlesAccepted';
import Conferences from './forms/ConferenceProceedings';
import PatentCopyrights from './forms/PatentCopyrights';
import { resolveCvPopulationSteps } from './meta/steps';
import HonourDistinction from './forms/HonourDistinction';
import UniversityEducation from './forms/UniversityEducation';
import BookChaptersAccepted from './forms/BookChaptersAccepted';
import PreviewComponent, { FullscreenPreview } from './preview';
import AcademicQualification from './forms/AcademicQualifications';
import {
  useFormListContextHandlerFactory,
  useSingleFormContextHandlerFactory,
} from './utils/abs';

const PREFIXES = {
  book: 'book',
  articles: 'articles',
  research: 'research',
  teaching: 'teaching',
  technical: 'technical',
  monographs: 'monographs',
  scholarship: 'scholarship',
  book_chapters: 'book_chapters',
  editorial_work: 'editorial_work',
  accepted_books: 'accepted_books',
  research_focus: 'research_focus',
  work_experience: 'work_experience',
  personal_details: 'personal_details',
  accepted_articles: 'accepted_articles',
  patent_copyrights: 'patent_copyrights',
  major_conferences: 'major_conferences',
  honour_distinction: 'honour_distinction',
  university_education: 'university_education',
  accepted_book_chapters: 'accepted_book_chapters',
  conference_proceedings: 'conference_proceedings',
  academic_qualifications: 'academic_qualifications',
  professional_qualifications: 'professional_qualifications',
  position_sought: 'position_sought',
};

const FormNavigationTitle = ({ title, selected = false, onClick }) => {
  return (
    <Box
      _hover={{
        cursor: 'poInter',
      }}
      py={3}
      px={7}
      flex="0 0 auto"
      overflow="scroll"
      onClick={onClick}
      borderRight={'1px solid'}
      backgroundColor={selected && '#267D77'}
      color={selected ? 'white' : '#0d282d59'}
      borderLeftColor={'rgba(13, 40, 45, 0.2)'}
    >
      {title}
    </Box>
  );
};

const BaseFormComponent = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const history = useHistory();
  const globalStateTuple = useState({});
  // TODO: remove this

  const { loading, data } = useGetHook(axiosFactory(), '/cv/get', {
    guards: [AuthGuard(history)],
  });

  const [showFullScreen, setFullScreenActive] = useState(false);

  const { makeRequest } = usePostHook(axiosFactory(), '/cv/update', {
    guards: [AuthGuard(history)],
  });

  useEffect(() => {
    if (!data) return;
    globalStateTuple[1](
      typeof data.data.state === 'string' ? JSON.parse(data.data.state) : {}
    );
  }, [data]);

  useEffect(() => {
    if (!data) return;
    if (data.data.state !== JSON.stringify(globalStateTuple[0])) {
      updateCvState(globalStateTuple[0]);
    }
  }, [globalStateTuple[0]]);

  // saves every 3 seconds
  const updateCvState = useCallback(
    debounce(val => makeRequest({ state: JSON.stringify(val) }), 3000),
    []
  );

  const {
    getter: getPersonalDetails,
    setter: setPersonalDetails,
    state: personalDetails,
  } = useSingleFormContextHandlerFactory(
    PREFIXES.personal_details,
    globalStateTuple
  );

  const {
    getter: getPositionSought,
    setter: setPositionSought,
    state: positionSought,
  } = useSingleFormContextHandlerFactory(
    PREFIXES.position_sought,
    globalStateTuple
  );

  const {
    getter: getResearchFocus,
    setter: setResearchFocus,
    state: researchFocus,
  } = useSingleFormContextHandlerFactory(
    PREFIXES.research_focus,
    globalStateTuple
  );
  // accessor for university education
  // DUPLICATE FOR ANY MULTIPLE
  const {
    getter: universityEducationGetter,
    setter: universityEducationSetter,
    state: universityEducation,
    addNewItem: addNewUniversity,
    removeAll: removeAllUniversities,
    removeItem: removeUniversity,
  } = useFormListContextHandlerFactory(
    PREFIXES.university_education,
    globalStateTuple
  );

  // accessor for academic qualifications

  const {
    getter: academicQualificationGetter,
    setter: academicQualificationSetter,
    state: academicQualifications,
    addNewItem: addAcademicQualification,
    removeAll: removeAllAcademicQualificaitons,
    removeItem: removeAcademicQualification,
  } = useFormListContextHandlerFactory(
    PREFIXES.academic_qualifications,
    globalStateTuple
  );

  // accessor for books
  const {
    getter: bookGetter,
    setter: bookSetter,
    state: books,
    addNewItem: addNewBook,
    removeAll: removeAllBooks,
    removeItem: removeBook,
  } = useFormListContextHandlerFactory(PREFIXES.book, globalStateTuple);

  //accessor for Patent & Copyrights
  const {
    getter: patentGetter,
    setter: patentSetter,
    state: patentCopyrights,
    addNewItem: addNewPatent,
    removeAll: removeAllPatents,
    removeItem: removePatent,
  } = useFormListContextHandlerFactory(
    PREFIXES.patent_copyrights,
    globalStateTuple
  );

  //accessor for Scholarship
  const {
    getter: scholarshipGetter,
    setter: scholarshipSetter,
    state: scholarship,
    addNewItem: addNewScholarship,
    removeAll: removeAllScholarships,
    removeItem: removeScholarship,
  } = useFormListContextHandlerFactory(PREFIXES.scholarship, globalStateTuple);

  //accessor for Honour and Distinction
  const {
    getter: honourGetter,
    setter: honourSetter,
    state: honourDistinction,
    addNewItem: addNewHonour,
    removeAll: removeAllHonours,
    removeItem: removeHonour,
  } = useFormListContextHandlerFactory(
    PREFIXES.honour_distinction,
    globalStateTuple
  );

  //accessor for Work Experience
  const {
    getter: workExperienceGetter,
    setter: workExperienceSetter,
    state: workExperience,
    addNewItem: addNewWorkExperience,
    removeAll: removeAllWorkExperiences,
    removeItem: removeWorkExperience,
  } = useFormListContextHandlerFactory(
    PREFIXES.work_experience,
    globalStateTuple
  );

  // accessor for professional
  const {
    getter: articlesGetter,
    setter: articlesSetter,
    state: articles,
    addNewItem: addNewArticle,
    removeAll: removeAllArticles,
    removeItem: removeArticle,
  } = useFormListContextHandlerFactory(PREFIXES.articles, globalStateTuple);

  const {
    getter: articlesAcceptedGetter,
    setter: articlesAcceptedSetter,
    state: articlesAccepted,
    addNewItem: addNewArticleAccepted,
    removeAll: removeAllArticlesAccepted,
    removeItem: removeArticleAccepted,
  } = useFormListContextHandlerFactory(
    PREFIXES.accepted_articles,
    globalStateTuple
  );

  const {
    getter: editorialWorkGetter,
    setter: editorialWorkSetter,
    state: editorialWork,
    addNewItem: addNewEditorialWork,
    removeAll: removeAllEditorialWork,
    removeItem: removeEditorialWork,
  } = useFormListContextHandlerFactory(
    PREFIXES.editorial_work,
    globalStateTuple
  );
  // accessor for books accepted
  const {
    getter: professionalQualificationsGetter,
    setter: professionalQualificationsSetter,
    state: professional,
    addNewItem: addNewProfessional,
    removeAll: removeAllProfessionalQualifications,
    removeItem: removeProfessionalQualification,
  } = useFormListContextHandlerFactory(
    PREFIXES.professional_qualifications,
    globalStateTuple
  );

  // accessor for books chapters
  const {
    getter: bookChaptersGetter,
    setter: bookChaptersSetter,
    state: bookChapters,
    addNewItem: addNewBookChapter,
    removeAll: removeAllBookChapters,
    removeItem: removeBookChapter,
  } = useFormListContextHandlerFactory(
    PREFIXES.book_chapters,
    globalStateTuple
  );

  // accessor for books chapters accepted
  const {
    getter: bookChaptersAcceptedGetter,
    setter: bookChaptersAcceptedSetter,
    state: bookChaptersAccepted,
    addNewItem: addNewBookChapterAccepted,
    removeAll: removeAllBookChaptersAccepted,
    removeItem: removeBookChapterAccepted,
  } = useFormListContextHandlerFactory(
    PREFIXES.accepted_book_chapters,
    globalStateTuple
  );

  // accessor for books accepted
  const {
    getter: booksAcceptedGetter,
    setter: booksAcceptedSetter,
    state: booksAccepted,
    addNewItem: addNewBookAccepted,
    removeAll: removeAllBookAccepted,
    removeItem: removeBookAccepted,
  } = useFormListContextHandlerFactory(
    PREFIXES.accepted_books,
    globalStateTuple
  );
  // accessor for monographs
  const {
    getter: monographGetter,
    setter: monographSetter,
    state: monographs,
    addNewItem: addNewMonograph,
    removeAll: removeAllMonographs,
    removeItem: removeMonograph,
  } = useFormListContextHandlerFactory(PREFIXES.monographs, globalStateTuple);

  // accessor for Technical Reports
  const {
    getter: technicalGetter,
    setter: technicalSetter,
    state: technical,
    addNewItem: addNewTechnical,
    removeAll: removeAllTechnical,
    removeItem: removeTechnical,
  } = useFormListContextHandlerFactory(PREFIXES.technical, globalStateTuple);

  // accessor for Revised Chapters
  const {
    getter: revisedChaptersGetter,
    setter: revisedChaptersSetter,
    state: revisedChapters,
    addNewItem: addNewRevisedChapters,
    removeAll: removeAllRevisedChapters,
    removeItem: removeRevisedChapter,
  } = useFormListContextHandlerFactory(
    PREFIXES.revisedChapters,
    globalStateTuple
  );

  // accessor for research
  const {
    getter: researchGetter,
    setter: researchSetter,
    state: research,
    addNewItem: addNewResearch,
  } = useFormListContextHandlerFactory(PREFIXES.research, globalStateTuple);

  // accessor for Conference
  const {
    getter: conferenceGetter,
    setter: conferenceSetter,
    state: conference,
    addNewItem: addNewConference,
    removeAll: removeAllConference,
    removeItem: removeConference,
  } = useFormListContextHandlerFactory(
    PREFIXES.conference_proceedings,
    globalStateTuple
  );

  // accessor for Teaching
  const {
    state: teachingExperience,
    getter: teachingGetter,
    setter: teachingSetter,
    addNewItem: addNewTeaching,
    removeItem: removeTeaching,
    removeAll: removeAllTeaching,
  } = useFormListContextHandlerFactory(PREFIXES.teaching, globalStateTuple);

  //accessor for Major Conferences
  const {
    state: majorConf,
    getter: majorConfGetter,
    setter: majorConfSetter,
    addNewItem: addNewMajorConf,
    removeItem: removeMajorConf,
    removeAll: removeAllMajorConf,
  } = useFormListContextHandlerFactory(
    PREFIXES.major_conferences,
    globalStateTuple
  );

  // titles for the different steps
  const titles = resolveCvPopulationSteps();

  if (loading)
    return (
      <>
        <CvHeader />
        <div
          style={{
            width: '100%',
            display: 'flex',
            minHeight: '500px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loader />
        </div>
      </>
    );

  return (
    <PositionSoughtContext.Provider
    value={{
      setter: setPositionSought,
      getter: getPositionSought,
      positionSought
    }}>
      <FullScreenContext.Provider
        value={{
          showFullScreen,
          currentStep: currentStep,
          nextStep: () => setCurrentStep(currentStep + 1),
          prevStep: () => setCurrentStep(currentStep - 1),
          toggleShowFullscreen: () =>
            setFullScreenActive(showFullScreen ? false : true),
        }}
      >
        <PersonalDetailsContext.Provider
          value={{
            personalDetails,
            setter: setPersonalDetails,
            getter: getPersonalDetails,
          }}
        >
          <UniversityEducationContext.Provider
            value={{
              universityEducation,
              setter: universityEducationSetter,
              getter: universityEducationGetter,
              addNew: addNewUniversity,
              remove: removeUniversity,
              removeAll: removeAllUniversities,
            }}
          >
            <AcademicQualificationContext.Provider
              value={{
                academicQualifications,
                setter: academicQualificationSetter,
                getter: academicQualificationGetter,
                addNew: addAcademicQualification,
                remove: removeAcademicQualification,
                removeAll: removeAllAcademicQualificaitons,
              }}
            >
              <BooksContext.Provider
                value={{
                  books,
                  setter: bookSetter,
                  getter: bookGetter,
                  addNew: addNewBook,
                  remove: removeBook,
                  removeAll: removeAllBooks,
                }}
              >
                <ProfessionalQualificationsContext.Provider
                  value={{
                    professional,
                    setter: professionalQualificationsSetter,
                    getter: professionalQualificationsGetter,
                    addNew: addNewProfessional,
                    remove: removeProfessionalQualification,
                    removeAll: removeAllProfessionalQualifications,
                  }}
                >
                  <PatentCopyrightsContext.Provider
                    value={{
                      patentCopyrights,
                      setter: patentSetter,
                      getter: patentGetter,
                      addNew: addNewPatent,
                      remove: removePatent,
                      removeAll: removeAllPatents,
                    }}
                  >
                    <ScholarshipContext.Provider
                      value={{
                        scholarship,
                        setter: scholarshipSetter,
                        getter: scholarshipGetter,
                        addNew: addNewScholarship,
                        remove: removeScholarship,
                        removeAll: removeAllScholarships,
                      }}
                    >
                      <HonourDistinctionContext.Provider
                        value={{
                          honourDistinction,
                          setter: honourSetter,
                          getter: honourGetter,
                          addNew: addNewHonour,
                          remove: removeHonour,
                          removeAll: removeAllHonours,
                        }}
                      >
                        <WorkExperienceContext.Provider
                          value={{
                            workExperience,
                            setter: workExperienceSetter,
                            getter: workExperienceGetter,
                            addNew: addNewWorkExperience,
                            remove: removeWorkExperience,
                            removeAll: removeAllWorkExperiences,
                          }}
                        >
                          <ArticlesAcceptedContext.Provider
                            value={{
                              articlesAccepted,
                              setter: articlesAcceptedSetter,
                              getter: articlesAcceptedGetter,
                              addNew: addNewArticleAccepted,
                              remove: removeArticleAccepted,
                              removeAll: removeAllArticlesAccepted,
                            }}
                          >
                            <ArticleContext.Provider
                              value={{
                                articles,
                                getter: articlesGetter,
                                setter: articlesSetter,
                                addNew: addNewArticle,
                                remove: removeArticle,
                                removeAll: removeAllArticles,
                              }}
                            >
                              <ResearchContext.Provider
                                value={{
                                  research,
                                  setter: researchSetter,
                                  getter: researchGetter,
                                  addNew: addNewResearch,
                                }}
                              >
                                <EditorialWorkContext.Provider
                                  value={{
                                    editorialWork,
                                    getter: editorialWorkGetter,
                                    setter: editorialWorkSetter,
                                    addNew: addNewEditorialWork,
                                    remove: removeEditorialWork,
                                    removeAll: removeAllEditorialWork,
                                  }}
                                >
                                  <BooksAcceptedContext.Provider
                                    value={{
                                      booksAccepted,
                                      setter: booksAcceptedSetter,
                                      getter: booksAcceptedGetter,
                                      addNew: addNewBookAccepted,
                                      remove: removeBookAccepted,
                                      removeAll: removeAllBookAccepted,
                                    }}
                                  >
                                    <BookChaptersContext.Provider
                                      value={{
                                        bookChapters,
                                        setter: bookChaptersSetter,
                                        getter: bookChaptersGetter,
                                        addNew: addNewBookChapter,
                                        remove: removeBookChapter,
                                        removeAll: removeAllBookChapters,
                                      }}
                                    >
                                      <BookChaptersAcceptedContext.Provider
                                        value={{
                                          bookChaptersAccepted,
                                          setter: bookChaptersAcceptedSetter,
                                          getter: bookChaptersAcceptedGetter,
                                          addNew: addNewBookChapterAccepted,
                                          remove: removeBookChapterAccepted,
                                          removeAll:
                                            removeAllBookChaptersAccepted,
                                        }}
                                      >
                                        <MonographContext.Provider
                                          value={{
                                            monographs,
                                            setter: monographSetter,
                                            getter: monographGetter,
                                            addNew: addNewMonograph,
                                            remove: removeMonograph,
                                            removeAll: removeAllMonographs,
                                          }}
                                        >
                                          <TechnicalContext.Provider
                                            value={{
                                              technical,
                                              setter: technicalSetter,
                                              getter: technicalGetter,
                                              addNew: addNewTechnical,
                                              remove: removeTechnical,
                                              removeAll: removeAllTechnical,
                                            }}
                                          >
                                            <RevisedChaptersContext.Provider
                                              value={{
                                                revisedChapters,
                                                setter: revisedChaptersSetter,
                                                getter: revisedChaptersGetter,
                                                addNew: addNewRevisedChapters,
                                                remove: removeRevisedChapter,
                                                removeAll:
                                                  removeAllRevisedChapters,
                                              }}
                                            >
                                              <ConferencesContext.Provider
                                                value={{
                                                  conference,
                                                  setter: conferenceSetter,
                                                  getter: conferenceGetter,
                                                  addNew: addNewConference,
                                                  remove: removeConference,
                                                  removeAll:
                                                    removeAllConference,
                                                }}
                                              >
                                                <TeachingContext.Provider
                                                  value={{
                                                    teachingExperience,
                                                    setter: teachingSetter,
                                                    getter: teachingGetter,
                                                    addNew: addNewTeaching,
                                                    remove: removeTeaching,
                                                    removeAll:
                                                      removeAllTeaching,
                                                  }}
                                                >
                                                  <ResearchFocusContext.Provider
                                                    value={{
                                                      researchFocus,
                                                      setter: setResearchFocus,
                                                      getter: getResearchFocus,
                                                    }}
                                                  >
                                                    {' '}
                                                    <MajorConfContext.Provider
                                                      value={{
                                                        majorConf,
                                                        setter: majorConfSetter,
                                                        getter: majorConfGetter,
                                                        addNew: addNewMajorConf,
                                                        remove: removeMajorConf,
                                                        removeAll:
                                                          removeAllMajorConf,
                                                      }}
                                                    >
                                                      <>
                                                        {/* Nav Header section */}
                                                        <CvHeader />
                                                        {/* Navigation stack ( for different form sections ) */}
                                                        <HStack
                                                          m={4}
                                                          top={2}
                                                          zIndex={2}
                                                          spacing={0}
                                                          marginLeft={4}
                                                          borderRadius={4}
                                                          overflow="scroll"
                                                          position="sticky"
                                                          backgroundColor="#DEEAE9"
                                                          sx={{
                                                            '::-webkit-scrollbar':
                                                              {
                                                                display: 'none',
                                                              },
                                                          }}
                                                        >
                                                          {Object.keys(
                                                            titles
                                                          ).map(titleKey => (
                                                            <FormNavigationTitle
                                                              key={titleKey}
                                                              title={
                                                                titles[titleKey]
                                                              }
                                                              selected={
                                                                Number(
                                                                  titleKey
                                                                ) <= currentStep
                                                              }
                                                              onClick={() =>
                                                                setCurrentStep(
                                                                  Number(
                                                                    titleKey
                                                                  )
                                                                )
                                                              }
                                                            />
                                                          ))}
                                                        </HStack>

                                                        {/* Form and preview section */}
                                                        <Grid
                                                          templateColumns="1fr 1fr"
                                                          py={16}
                                                          position="relative"
                                                        >
                                                          <Flex
                                                            mb={8}
                                                            width="100%"
                                                            height="100%"
                                                            align="center"
                                                            direction="column"
                                                            justify="space-between"
                                                          >
                                                            {currentStep ===
                                                              1 && (
                                                              <PersonalDetails />
                                                            )}
                                                            {currentStep ===
                                                              2 && (
                                                              <UniversityEducation />
                                                            )}
                                                            {currentStep ===
                                                              3 && (
                                                              <AcademicQualification />
                                                            )}
                                                            {currentStep ===
                                                              4 && (
                                                              <Professional />
                                                            )}
                                                            {currentStep ===
                                                              5 && (
                                                              <Scholarship />
                                                            )}
                                                            {currentStep ===
                                                              8 && <Research />}
                                                            {currentStep ===
                                                              9 && <Books />}
                                                            {currentStep ===
                                                              10 && (
                                                              <BookChapters />
                                                            )}

                                                            {currentStep ===
                                                              16 && (
                                                              <RevisedChapters />
                                                            )}
                                                            {currentStep ===
                                                              17 && (
                                                              <EditorialWork />
                                                            )}
                                                            {currentStep ===
                                                              6 && (
                                                              <HonourDistinction />
                                                            )}
                                                            {currentStep ===
                                                              11 && (
                                                              <Conferences />
                                                            )}
                                                            {currentStep ===
                                                              7 && (
                                                              <>
                                                                <WorkExperience />
                                                                <Teaching />
                                                              </>
                                                            )}
                                                            {currentStep ===
                                                              12 && (
                                                              <PatentCopyrights />
                                                            )}
                                                            {currentStep ===
                                                              13 && (
                                                              <Articles />
                                                            )}
                                                            {currentStep ===
                                                              14 && (
                                                              <>
                                                                <BooksAccepted />
                                                                <BookChaptersAccepted />
                                                                <ArticlesAccepted />
                                                              </>
                                                            )}
                                                            {currentStep ===
                                                              15 && (
                                                              <>
                                                                <Technical />
                                                                <Monograph />
                                                              </>
                                                            )}
                                                            {currentStep ===
                                                              18 && (
                                                              <ResearchFocus />
                                                            )}
                                                            {currentStep ===
                                                              19 && (
                                                              <MajorConf />
                                                            )}
                                                          </Flex>
                                                          <PreviewComponent />
                                                          {showFullScreen && (
                                                            <FullscreenPreview />
                                                          )}
                                                        </Grid>
                                                      </>
                                                    </MajorConfContext.Provider>
                                                  </ResearchFocusContext.Provider>
                                                </TeachingContext.Provider>
                                              </ConferencesContext.Provider>
                                            </RevisedChaptersContext.Provider>
                                          </TechnicalContext.Provider>
                                        </MonographContext.Provider>
                                      </BookChaptersAcceptedContext.Provider>
                                    </BookChaptersContext.Provider>
                                  </BooksAcceptedContext.Provider>
                                </EditorialWorkContext.Provider>
                              </ResearchContext.Provider>
                            </ArticleContext.Provider>
                          </ArticlesAcceptedContext.Provider>
                        </WorkExperienceContext.Provider>
                      </HonourDistinctionContext.Provider>
                    </ScholarshipContext.Provider>
                  </PatentCopyrightsContext.Provider>
                </ProfessionalQualificationsContext.Provider>
              </BooksContext.Provider>
            </AcademicQualificationContext.Provider>
          </UniversityEducationContext.Provider>
        </PersonalDetailsContext.Provider>
      </FullScreenContext.Provider>
    </PositionSoughtContext.Provider>
  );
};

export default BaseFormComponent;
