import {
  Box,
  Flex,
  Textarea,
  Stack,
  HStack,
  Divider,
  Heading,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { ResearchFocusContext } from '../context';
import { getOnChangeHandlerFromSetter } from '../utils';

export const ResearchFocusPreview = ({ style }) => {
  const { getter } = useContext(ResearchFocusContext);

  return (
    <div className="cv-section break-avoid">
      <div style={style}>
        <p className="underline bold">Research Focus:</p>
        <p
          style={{
            marginTop: '10px',
            whiteSpace: 'break-spaces',
            textAlign: 'justify',
          }}
        >
          {getter('focus')}
        </p>
      </div>
    </div>
  );
};

const ResearchFocus = () => {
  const { setter, getter } = useContext(ResearchFocusContext);

  const onInputChange = getOnChangeHandlerFromSetter(setter);

  return (
    <>
      <Flex
        mb={8}
        px={12}
        width="50%"
        height="100%"
        align="center"
        maxWidth="500px"
        direction="column"
        justify="space-between"
      >
        <Box
          p={6}
          mb={10}
          width="530px"
          boxShadow="0px 7px 50px rgba(232, 242, 241, 0.8)"
        >
          <Box>
            <HStack align="flex-start" justify="space-between">
              <Box>
                <Heading fontSize="xl" color="teal.700" fontWeight="400">
                  Research Focus
                </Heading>
                <Box
                  mt={3}
                  width="80px"
                  h="3px"
                  backgroundColor="#4ECDC4"
                ></Box>
              </Box>
            </HStack>
          </Box>
          <>
            <Divider />
            <Stack spacing={6} py={4}>
              <HStack>
                <Textarea
                  py={6}
                  type="text"
                  minHeight={'400px'}
                  name="focus"
                  aria-label="Details"
                  value={getter('focus')}
                  onChange={onInputChange}
                  placeholder="Details"
                />
              </HStack>
            </Stack>
          </>
        </Box>
      </Flex>
    </>
  );
};

export default ResearchFocus;
