export const AuthGuard = history => async (response, err) => {
  if (err && err.response && err.response.status === 401) {
    // remove all forms of auth cred
    history.push({
      pathname: '/login',
      search: `redirect=${window.location.pathname}`,
    });
  }
  return;
};
