import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  Input,
  Stack,
  Text,
  Divider,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { IoIosAddCircleOutline } from 'react-icons/io';

import isEmpty from '../utils/isEmpty';
import asterisk from '../utils/asterisk';
import findIndex from '../utils/position';
import DeleteSection from '../preview/generic/delete';
import { MonographContext, PersonalDetailsContext } from '../context';

export const MonographPreview = () => {
  const { getter, monographs } = useContext(MonographContext);
  const { personalDetails } = useContext(PersonalDetailsContext);

  const initials = `${personalDetails.personal_details_surname}, ${personalDetails.personal_details_initials}`;

  const lastTrialYear =
    personalDetails.personal_details_dateOfLastPromotionTrial;

  const lastPromotion = personalDetails.personal_details_dateOfLastPromotion;

  const bold = (authors, position = 1) => {
    const formatted = findIndex(authors).map((each, index) =>
      index === parseInt(position) && each === initials ? (
        <b>{each} </b>
      ) : (
        each + ' '
      )
    );

    return formatted;
  };

  return (
    <>
      {!isEmpty(monographs) &&
        monographs.map((each, index) => (
          <li
            key={index}
            style={{
              marginLeft: '40px',
            }}
            className={asterisk(
              lastPromotion,
              lastTrialYear,
              getter('year', index)
            )}
          >
            <div className="two-column" style={{ marginLeft: '10px' }}>
              <p>
                {getter('authors', index) &&
                  bold(getter('authors', index), getter('position', index))}
                ({getter('year', index)}) {getter('title', index)}{' '}
                {getter('publisher', index)} {getter('others', index)} (
                {getter('country', index)}) (Contribution:{' '}
                {getter('contribution', index)}%)
              </p>
            </div>
          </li>
        ))}
    </>
  );
};

const Monograph = () => {
  const { monographs, addNew, getter, setter, remove, removeAll } =
    useContext(MonographContext);

  const handleInputChangeForIndex = index => e => {
    setter(e.target.name, e.target.value, index);
  };

  return (
    <>
      <Flex
        mb={8}
        px={12}
        width="50%"
        height="100%"
        align="center"
        maxWidth="500px"
        direction="column"
        justify="space-between"
      >
        <Box
          p={6}
          mb={10}
          width="530px"
          boxShadow="0px 7px 50px rgba(232, 242, 241, 0.8)"
        >
          <Box>
            <HStack align="flex-start" justify="space-between">
              <Box>
                <Heading fontSize="xl" color="teal.700" fontWeight="400">
                  Monograph
                </Heading>
                <Box
                  mt={3}
                  width="80px"
                  h="3px"
                  backgroundColor="#4ECDC4"
                ></Box>
              </Box>
              <Text
                textDecoration="underline"
                color="#1A535C"
                onClick={() => removeAll()}
              >
                clear fields
              </Text>
            </HStack>
          </Box>

          {monographs.map((each, index) => (
            <Stack key={index} spacing={6} py={4}>
              <Divider />
              <DeleteSection onClick={() => remove(index)} />
              <Input
                py={6}
                type="text"
                name="authors"
                aria-label="Authors"
                placeholder="Authors"
                value={getter('authors', index)}
                onChange={handleInputChangeForIndex(index)}
              />
              <Input
                py={6}
                type="text"
                name="title"
                aria-label="Title"
                placeholder="Title"
                value={getter('title', index)}
                onChange={handleInputChangeForIndex(index)}
              />

              <Input
                py={6}
                type="text"
                name="others"
                aria-label="Others"
                placeholder="Others"
                value={getter('others', index)}
                onChange={handleInputChangeForIndex(index)}
              />
              <HStack>
                <Input
                  py={6}
                  type="number"
                  name="position"
                  aria-label="Position"
                  placeholder="Position"
                  value={getter('position', index)}
                  onChange={handleInputChangeForIndex(index)}
                />
                <Input
                  py={6}
                  type="date"
                  name="year"
                  aria-label="Year Published"
                  placeholder="Year Published"
                  value={getter('year', index)}
                  onChange={handleInputChangeForIndex(index)}
                />
              </HStack>
              <HStack>
                <Input
                  py={6}
                  type="text"
                  name="country"
                  aria-label="Country"
                  placeholder="Country"
                  value={getter('country', index)}
                  onChange={handleInputChangeForIndex(index)}
                />
                <Input
                  py={6}
                  type="number"
                  name="contribution"
                  aria-label="Contribution"
                  placeholder="Contribution"
                  value={getter('contribution', index)}
                  onChange={handleInputChangeForIndex(index)}
                />
              </HStack>
              <Input
                py={6}
                type="text"
                name="publication_url"
                aria-label="Link to Publication Online"
                placeholder="Link to Publication Online"
                value={getter('publication_url', index)}
                onChange={handleInputChangeForIndex(index)}
              />
            </Stack>
          ))}
          <Button
            py={6}
            px={6}
            isFullWidth
            onClick={addNew}
            fontWeight={400}
            variant="outline"
            colorScheme="teal"
            borderStyle="dashed"
          >
            <Icon as={IoIosAddCircleOutline} w={6} h={6} marginRight={3} />
            Add more
          </Button>
        </Box>
      </Flex>
    </>
  );
};

export default Monograph;
