import {
  Box,
  Flex,
  Link,
  Text,
  Image,
  HStack,
  Circle,
  VStack,
  Heading,
} from '@chakra-ui/react';
import { useBoolean } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Link as RRLink } from 'react-router-dom';

import UI from '../assets/images/image.png';
import CreateProfileForm from '../components/CreateProfileForm';

const Signup = () => {
  const [flag, setFlag] = useBoolean();

  return (
    <Flex minH={'100vh'} direction={{ base: 'column', md: 'row' }}>
      <Flex
        p={10}
        flex={0.36}
        flexDirection="column"
        alignItems="flex-start"
        backgroundColor="#267D77"
        justifyContent="space-between"
      >
        <RRLink to="/" spacing={6}>
          <Image src={UI} boxSize="3rem" alt="university of ibadan" />
        </RRLink>
        <Box color="white" py={10}>
          <Heading fontSize="2xl" mb={10}>
            Registration is{' '}
            <Text as="span" color="#FFD81E">
              simple and fast.
            </Text>
          </Heading>
          <VStack display="none" spacing={14} alignItems="flex-start">
            <HStack fontSize="1.125rem">
              <Circle
                width="1.75rem"
                height="1.75rem"
                color="#1D8D9E"
                backgroundColor="white"
              >
                <Text>1</Text>
              </Circle>
              <Text>Creating your account</Text>
            </HStack>
            <HStack fontSize="1.125rem" opacity={flag ? 1 : 0.6}>
              <Circle
                width="1.75rem"
                height="1.75rem"
                color="#1D8D9E"
                backgroundColor="white"
              >
                <Text>2</Text>
              </Circle>
              <Text>Setting up your profile</Text>
            </HStack>
          </VStack>
        </Box>
        <Flex alignItems="center" display={{ base: 'none', md: 'flex' }}>
          <ArrowBackIcon w={5} h={5} marginRight={4} color="#FFD81E" />
          <Link href="/" color="white">
            Back to Homepage
          </Link>
        </Flex>
      </Flex>

      <CreateProfileForm toggle={() => setFlag.toggle()} />
    </Flex>
  );
};

export default Signup;
