import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { ArticleContext, PersonalDetailsContext } from '../context';
import DeleteSection from '../preview/generic/delete';
import asterisk from '../utils/asterisk';
import isEmpty from '../utils/isEmpty';
import findIndex from '../utils/position';
import { Field } from '../utils/special';

export const ArticlePreview = () => {
  const { getter, articles } = useContext(ArticleContext);
  const { personalDetails } = useContext(PersonalDetailsContext);

  const initials = `${personalDetails.personal_details_surname}, ${personalDetails.personal_details_initials}`;

  const lastTrialYear =
    personalDetails.personal_details_dateOfLastPromotionTrial;

  const lastPromotion = personalDetails.personal_details_dateOfLastPromotion;

  const bold = (authors, position = 1) => {
    const formatted = findIndex(authors).map((each, index) =>
      index === parseInt(position) && each === initials ? (
        <b>{each} </b>
      ) : (
        each + ' '
      )
    );

    return formatted;
  };

  return (
    <div className="cv-section cv-section-inner">
      <div
        style={{
          display: 'flex',
        }}
      >
        <div
          className="two-column"
          style={{ paddingLeft: '26px', marginBottom: '10px' }}
        >
          <p>
            (e)
            <span className="underline" style={{ marginLeft: '10px' }}>
              <Field>
                Articles that have already appeared in Learned journals:
              </Field>
            </span>
          </p>
          <p>{isEmpty(articles) && 'Nil'}</p>
        </div>
      </div>
      <div>
        {!isEmpty(articles) &&
          articles.map((each, index) => (
            <li
              key={index}
              style={{
                marginLeft: '40px',
              }}
              className={asterisk(
                lastPromotion,
                lastTrialYear,
                getter('year', index)
              )}
            >
              <div className="two-column" style={{ marginLeft: '10px' }}>
                <p>
                  {getter('authors', index) &&
                    bold(getter('authors', index), getter('position', index))}
                  ({getter('year', index)}) {getter('title', index)}{' '}
                  {getter('journal', index)} {getter('others', index)} (
                  {getter('country', index)}) (Contribution:{' '}
                  {getter('contribution', index)}%)
                </p>
              </div>
            </li>
          ))}
      </div>
    </div>
  );
};

const Articles = () => {
  const { articles, setter, getter, addNew, remove, removeAll } =
    useContext(ArticleContext);

  const handleInputChangeForIndex = index => e => {
    setter(e.target.name, e.target.value, index);
  };
  return (
    <>
      <Flex
        mb={8}
        px={12}
        width="50%"
        height="100%"
        align="center"
        maxWidth="500px"
        direction="column"
        justify="space-between"
      >
        <Box
          p={6}
          mb={10}
          width="530px"
          boxShadow="0px 7px 50px rgba(232, 242, 241, 0.8)"
        >
          <Box>
            <HStack align="flex-start" justify="space-between">
              <Box>
                <Heading fontSize="xl" color="teal.700" fontWeight="400">
                  Articles
                </Heading>
                <Box
                  mt={3}
                  width="80px"
                  h="3px"
                  backgroundColor="#4ECDC4"
                ></Box>
              </Box>
              <Text
                textDecoration="underline"
                color="#1A535C"
                onClick={() => removeAll()}
              >
                clear fields
              </Text>
            </HStack>
          </Box>

          <Stack spacing={6} py={4}>
            {articles.map((each, index) => (
              <>
                <DeleteSection onClick={() => remove(index)} />
                <Input
                  py={6}
                  type="text"
                  name="authors"
                  onChange={handleInputChangeForIndex(index)}
                  aria-label="Authors"
                  placeholder="Authors"
                  value={getter('authors', index)}
                />

                <Input
                  py={6}
                  type="text"
                  name="title"
                  onChange={handleInputChangeForIndex(index)}
                  aria-label="Title"
                  placeholder="Title"
                  value={getter('title', index)}
                />
                <Input
                  py={6}
                  type="text"
                  name="journal"
                  onChange={handleInputChangeForIndex(index)}
                  aria-label="Journal"
                  placeholder="Journal"
                  value={getter('journal', index)}
                />
                <Input
                  py={6}
                  type="text"
                  name="others"
                  onChange={handleInputChangeForIndex(index)}
                  aria-label="Others"
                  placeholder="Others"
                  value={getter('others', index)}
                />
                <HStack>
                  <Input
                    py={6}
                    type="number"
                    name="year"
                    onChange={handleInputChangeForIndex(index)}
                    aria-label="Year"
                    placeholder="Year"
                    value={getter('year', index)}
                  />
                  <Input
                    py={6}
                    type="number"
                    name="position"
                    onChange={handleInputChangeForIndex(index)}
                    aria-label="Author's Position"
                    placeholder="Author's Position"
                    value={getter('position', index)}
                  />
                </HStack>
                <HStack>
                  <Input
                    py={6}
                    type="number"
                    name="contribution"
                    onChange={handleInputChangeForIndex(index)}
                    aria-label="Contribution"
                    placeholder="Contribution"
                    value={getter('contribution', index)}
                  />
                  <Input
                    py={6}
                    type="text"
                    name="country"
                    aria-label="Country"
                    placeholder="Country"
                    value={getter('country', index)}
                    onChange={handleInputChangeForIndex(index)}
                  />
                </HStack>
                <Input
                  py={6}
                  type="text"
                  name="publication_url"
                  aria-label="Link to Publication Online"
                  placeholder="Link to Publication Online"
                  value={getter('publication_url', index)}
                  onChange={handleInputChangeForIndex(index)}
                />
              </>
            ))}

            <Button
              py={6}
              px={6}
              fontWeight={400}
              onClick={addNew}
              variant="outline"
              colorScheme="teal"
              borderStyle="dashed"
            >
              <Icon as={IoIosAddCircleOutline} w={6} h={6} marginRight={3} />
              Add another Article
            </Button>
          </Stack>
        </Box>
      </Flex>
    </>
  );
};

export default Articles;
