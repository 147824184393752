import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  Input,
  Stack,
  Text,
  Divider,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { EditorialWorkContext } from '../context';
import DeleteSection from '../preview/generic/delete';
import SectionHeader from '../preview/generic/SectionHeader';

export const EditorialWorkPreview = () => {
  const { getter, editorialWork } = useContext(EditorialWorkContext);
  return (
    <div>
      <SectionHeader underline={false} value="Editorial Works" />
      {editorialWork.map((each, index) => (
        <div style={{ margin: '4px 16px' }}>
          <div>
            {' '}
            Authors: <b>{getter('article_authors', index)}</b>
          </div>
          <div>
            {' '}
            Article Year: <b>{getter('article_year', index)}</b>
          </div>
          <div>
            {' '}
            Article Title: <b>{getter('article_title', index)}</b>
          </div>
          <div>
            {' '}
            Article Journal: <b>{getter('article_journal', index)}</b>
          </div>
          <div>
            {' '}
            Country: <b>{getter('article_country', index)}</b>
          </div>
          <div>
            {' '}
            Contribution: <b>{getter('article_contribution', index)}</b>
          </div>
          <div>
            {' '}
            Status: <b>{getter('article_contribution', index)}</b>
          </div>
        </div>
      ))}
    </div>
  );
};

const EditorialWork = () => {
  // eslint-disable-next-line
  const { editorialWork, setter, getter, addNew, remove, removeAll } =
    useContext(EditorialWorkContext);

  const handleInputChangeForIndex = index => e => {
    setter(e.target.name, e.target.value, index);
  };
  return (
    <>
      <Flex
        mb={8}
        px={12}
        width="50%"
        height="100%"
        align="center"
        maxWidth="500px"
        direction="column"
        justify="space-between"
      >
        <Box
          p={6}
          mb={10}
          width="530px"
          boxShadow="0px 7px 50px rgba(232, 242, 241, 0.8)"
        >
          <Box>
            <HStack align="flex-start" justify="space-between">
              <Box>
                <Heading fontSize="xl" color="teal.700" fontWeight="400">
                  Editorial Works
                </Heading>
                <Box
                  mt={3}
                  width="80px"
                  h="3px"
                  backgroundColor="#4ECDC4"
                ></Box>
              </Box>
              <Text
                textDecoration="underline"
                color="#1A535C"
                onClick={() => removeAll()}
              >
                clear fields
              </Text>
            </HStack>
          </Box>

          <Stack spacing={6} py={4}>
            {editorialWork.map((each, index) => (
              <>
                <Divider />
                <DeleteSection onClick={() => remove(index)} />
                <Input
                  py={6}
                  type="text"
                  name="article_authors"
                  onChange={handleInputChangeForIndex(index)}
                  aria-label="Authors"
                  placeholder="Authors"
                />
                <Input
                  py={6}
                  type="text"
                  name="article_journal"
                  onChange={handleInputChangeForIndex(index)}
                  aria-label="Journal"
                  placeholder="Journal"
                />
                <HStack>
                  <Input
                    py={6}
                    type="number"
                    name="article_year"
                    onChange={handleInputChangeForIndex(index)}
                    aria-label="Year"
                    placeholder="Year"
                  />
                  <Input
                    py={6}
                    type="text"
                    name="article_title"
                    onChange={handleInputChangeForIndex(index)}
                    aria-label="Title"
                    placeholder="Title"
                  />
                </HStack>

                <HStack>
                  <Input
                    py={6}
                    type="text"
                    name="article_other"
                    onChange={handleInputChangeForIndex(index)}
                    aria-label="Other"
                    placeholder="Other"
                  />

                  <Input
                    py={6}
                    type="text"
                    name="article_country"
                    onChange={handleInputChangeForIndex(index)}
                    aria-label="Country"
                    placeholder="Country"
                  />
                </HStack>
                <HStack>
                  <Input
                    py={6}
                    type="number"
                    name="article_contribution"
                    onChange={handleInputChangeForIndex(index)}
                    aria-label="Contribution"
                    placeholder="Contribution"
                  />
                  <Input
                    py={6}
                    type="text"
                    onChange={handleInputChangeForIndex(index)}
                    name="article_status"
                    aria-label="Status"
                    placeholder="Status"
                  />
                </HStack>
                <Input
                  py={6}
                  type="text"
                  name="publication_url"
                  aria-label="Link to Publication Online"
                  placeholder="Link to Publication Online"
                  value={getter('publication_url', index)}
                  onChange={handleInputChangeForIndex(index)}
                />
              </>
            ))}

            <Button
              py={6}
              px={6}
              fontWeight={400}
              variant="outline"
              colorScheme="teal"
              borderStyle="dashed"
            >
              <Icon as={IoIosAddCircleOutline} w={6} h={6} marginRight={3} />
              Add another Editorial Work
            </Button>
          </Stack>
        </Box>
      </Flex>
    </>
  );
};

export default EditorialWork;
