import {
  Box,
  Button,
  Flex,
  Heading,
  Divider,
  HStack,
  Icon,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { AcademicQualificationContext } from '../context/index';
import DeleteSection from '../preview/generic/delete';
import isEmpty from '../utils/isEmpty';
import { Field } from '../utils/special';

export const AcademicQualificationPreview = () => {
  const { getter, academicQualifications } = useContext(
    AcademicQualificationContext
  );

  return (
    <div className="cv-section">
      <div
        style={{
          display: 'flex',
        }}
      >
        <p>IV.</p>
        <div className="two-column" style={{ paddingLeft: '24px' }}>
          <p className="underline">
            <Field>
              {' '}
              Academic Qualification (with dates and granting bodies):
            </Field>
          </p>{' '}
          <p>{isEmpty(academicQualifications) && 'Nil'}</p>
        </div>
      </div>
      <ol className="lower-roman">
        {!isEmpty(academicQualifications) &&
          academicQualifications.map((each, index) => (
            <li
              key={index}
              style={{
                marginLeft: '34px',
              }}
            >
              <p> </p>
              <div className="two-column" style={{ marginLeft: '4px' }}>
                <p>
                  {getter('degree', index)}, {getter('granting_bodies', index)}
                </p>{' '}
                <p>
                  <span>{getter('date_granted', index)}</span>
                </p>
              </div>
            </li>
          ))}
      </ol>
    </div>
  );
};

const AcademicQualifications = () => {
  // eslint-disable-next-line
  const { setter, addNew, academicQualifications, remove, removeAll, getter } =
    useContext(AcademicQualificationContext);

  const handleInputChangeForIndex = index => e => {
    setter(e.target.name, e.target.value, index);
  };

  return (
    <>
      <Flex
        mb={8}
        px={12}
        width="50%"
        height="100%"
        align="center"
        maxWidth="500px"
        direction="column"
        justify="space-between"
      >
        <Box
          p={6}
          mb={10}
          width="530px"
          boxShadow="0px 7px 50px rgba(232, 242, 241, 0.8)"
        >
          <Box>
            <HStack align="flex-start" justify="space-between">
              <Box>
                <Heading fontSize="xl" color="teal.700" fontWeight="400">
                  Academic Qualifications
                </Heading>
                <Box
                  mt={3}
                  width="80px"
                  h="3px"
                  backgroundColor="#4ECDC4"
                ></Box>
              </Box>
              <Text
                textDecoration="underline"
                color="#1A535C"
                onClick={() => removeAll()}
              >
                clear fields
              </Text>
            </HStack>
          </Box>
          <Stack spacing={6} py={4}>
            {academicQualifications.map((each, index) => (
              <>
                <Divider />
                <DeleteSection onClick={() => remove(index)} />
                <Input
                  py={6}
                  type="text"
                  name="granting_bodies"
                  value={getter('granting_bodies', index)}
                  onChange={handleInputChangeForIndex(index)}
                  aria-label="Granting Bodies (Institutions)"
                  placeholder="Granting Bodies (Institutions)"
                />
                <HStack>
                  <Input
                    py={6}
                    type="text"
                    name="degree"
                    value={getter('degree', index)}
                    onChange={handleInputChangeForIndex(index)}
                    aria-label="Degree"
                    placeholder="Degree"
                  />
                  <Input
                    py={6}
                    step="1"
                    min="1900"
                    max="2099"
                    type="number"
                    name="date_granted"
                    aria-label="Year granted"
                    placeholder="Year granted"
                    value={getter('date_granted', index)}
                    onChange={handleInputChangeForIndex(index)}
                  />
                </HStack>
              </>
            ))}
            <Button
              py={6}
              px={6}
              fontWeight={400}
              onClick={addNew}
              variant="outline"
              colorScheme="teal"
              borderStyle="dashed"
            >
              <Icon as={IoIosAddCircleOutline} w={6} h={6} marginRight={3} />
              Add another Qualification
            </Button>
          </Stack>
        </Box>
      </Flex>
    </>
  );
};

export default AcademicQualifications;
