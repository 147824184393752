import {
    Box,
    Button,
    Flex,
    Heading,
    Divider,
    HStack,
    Icon,
    Input,
    Stack,
    Text,
  } from '@chakra-ui/react';
  import React, { useContext } from 'react';
  import { IoIosAddCircleOutline } from 'react-icons/io';
  import { ResearchContext } from '../context';
  import SectionHeader from '../preview/generic/SectionHeader';
  
  export const ResearchPreview = () => {
    const { getter, research } = useContext(
      ResearchContext
    );
  
    return (
      <div>
        <SectionHeader value="Research" />
        {research.map((each, index) => (
          <div style={{ margin: '10px 20px' }}>
            <div>
              {' '}
              Granting Bodies: <b>{getter('granting_bodies', index)}</b>
            </div>
            <div>
              {' '}
              Degreee: <b>{getter('degree', index)}</b>
            </div>
            <div>
              {' '}
              Date Granted: <b>{getter('date_granted', index)}</b>
            </div>
          </div>
        ))}
      </div>
    );
  };
  
  const Research = () => {
    // eslint-disable-next-line
    const { getter, setter, addNew, research } = React.useContext(
      ResearchContext
    );
  
    const handleInputChangeForIndex = index => e => {
      setter(e.target.name, e.target.value, index);
    };
  
    return (
      <>
        <Flex
          mb={8}
          px={12}
          width="50%"
          height="100%"
          align="center"
          maxWidth="500px"
          direction="column"
          justify="space-between"
        >
          <Box
            p={6}
            mb={10}
            width="530px"
            boxShadow="0px 7px 50px rgba(232, 242, 241, 0.8)"
          >
            <Box>
              <HStack align="flex-start" justify="space-between">
                <Box>
                  <Heading fontSize="xl" color="teal.700" fontWeight="400">
                    Research
                  </Heading>
                  <Box
                    mt={3}
                    width="80px"
                    h="3px"
                    backgroundColor="#4ECDC4"
                  ></Box>
                </Box>
                <Text textDecoration="underline" color="#1A535C">
                  clear fields
                </Text>
              </HStack>
            </Box>
            <Stack spacing={6} py={4}>
              {research.map((each, index) => (
                <>
                  <Divider />
                  <Input
                    py={6}
                    type="text"
                    name="granting_bodies"
                    onChange={handleInputChangeForIndex(index)}
                    aria-label="Granting Bodies (Institutions)"
                    placeholder="Granting Bodies (Institutions)"
                  />
                  <HStack>
                    <Input
                      py={6}
                      type="text"
                      name="degree"
                      onChange={handleInputChangeForIndex(index)}
                      aria-label="Degree"
                      placeholder="Degree"
                    />
                    <Input
                      py={6}
                      type="text"
                      name="date_granted"
                      onChange={handleInputChangeForIndex(index)}
                      aria-label="Date granted"
                      placeholder="Date granted"
                    />
                  </HStack>
                </>
              ))}
              <Button
                py={6}
                px={6}
                fontWeight={400}
                onClick={addNew}
                variant="outline"
                colorScheme="teal"
                borderStyle="dashed"
              >
                <Icon as={IoIosAddCircleOutline} w={6} h={6} marginRight={3} />
                Add another Research
              </Button>
            </Stack>
          </Box>
        </Flex>
      </>
    );
  };
  
  export default Research;
  