/**
 * Returns Map<stepNumber, stepTitle>
 */
export const resolveCvPopulationSteps = () => ({
  1: 'Personal & Academic Details',
  2: 'University Education (with dates)',
  3: 'Academic Qualifications (with dates and granting bodies)',
  4: 'Professional Qualifications and Diplomas (with dates)',
  5: 'Scholarships, Fellowships and Prizes (with dates) in respect of Undergraduate and Postgraduate work only)',
  6: 'Honours, Distinctions and Membership of Learned Societies',
  7: 'Details of Teaching/Working Experience',
  8: 'Research',
  9: 'Books already published',
  10: 'Chapters in Books already Published',
  11: 'Articles that have already appeared in Refereed Conference Proceedings',
  12: 'Patents & Copyrights',
  13: 'Articles that have already appeared learned Journals',
  14: 'Books, Chapters in Books and Articles already accepted for Publications',
  15: 'Technical Report & Monographs',
  16: 'Chapters in Edited Books/Revised Chapters in Edited Books',
  17: 'Editorial Works',
  18: 'Research Focus',
  19: 'Major Conferences Attended with Papers Read (in the last 5 years)',
});
