import {
  Box,
  Flex,
  Icon,
  Text,
  Input,
  Stack,
  Button,
  HStack,
  Divider,
  Heading,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { IoIosAddCircleOutline } from 'react-icons/io';
import {
  TechnicalContext,
  PersonalDetailsContext,
  MonographContext,
} from '../context';
import DeleteSection from '../preview/generic/delete';
import findIndex from '../utils/position';
import asterisk from '../utils/asterisk';
import isEmpty from '../utils/isEmpty';
import { MonographPreview } from './Monograph';
import { Field } from '../utils/special';

export const TechnicalMonographsPreview = () => {
  const { technical } = useContext(TechnicalContext);
  const { monographs } = useContext(MonographContext);

  return (
    <div className="cv-section cv-section-inner">
      <div
        style={{
          display: 'flex',
        }}
      >
        <div
          className="two-column"
          style={{ paddingLeft: '26px', marginBottom: '10px' }}
        >
          <p>
            (f)
            <span className="underline" style={{ marginLeft: '10px' }}>
              <Field>Technical Reports and Monographs:</Field>
            </span>
          </p>
          <p>{isEmpty(technical) && isEmpty(monographs) && 'Nil'}</p>
        </div>
      </div>
      <div>
        <TechnicalPreview />
        <MonographPreview />
      </div>
    </div>
  );
};

export const TechnicalPreview = () => {
  const { getter, technical } = useContext(TechnicalContext);
  const { personalDetails } = useContext(PersonalDetailsContext);

  const initials = `${personalDetails.personal_details_surname}, ${personalDetails.personal_details_initials}`;

  const lastTrialYear =
    personalDetails.personal_details_dateOfLastPromotionTrial;

  const lastPromotion = personalDetails.personal_details_dateOfLastPromotion;

  const bold = (authors, position = 1) => {
    const formatted = findIndex(authors).map((each, index) =>
      index === parseInt(position) && each === initials ? (
        <b>{each} </b>
      ) : (
        each + ' '
      )
    );

    return formatted;
  };

  return (
    <>
      {!isEmpty(technical) &&
        technical.map((each, index) => (
          <li
            key={index}
            style={{
              marginLeft: '40px',
            }}
            className={asterisk(
              lastPromotion,
              lastTrialYear,
              getter('year', index)
            )}
          >
            <div className="two-column" style={{ marginLeft: '10px' }}>
              <p>
                {getter('authors', index) &&
                  bold(getter('authors', index), getter('position', index))}
                ({getter('year', index)}) {getter('title', index)}{' '}
                {getter('others', index)} {getter('country', index)}{' '}
                (Contribution: {getter('contribution', index)}%)
              </p>
            </div>
          </li>
        ))}
    </>
  );
};

const Technical = () => {
  // eslint-disable-next-line
  const [data, setData] = React.useState({});

  const { getter, setter, technical, addNew, remove, removeAll } =
    useContext(TechnicalContext);

  const handleInputChangeForIndex = index => e => {
    setter(e.target.name, e.target.value, index);
  };

  return (
    <>
      <Flex
        mb={8}
        px={12}
        width="50%"
        height="100%"
        align="center"
        maxWidth="500px"
        direction="column"
        justify="space-between"
      >
        <Box
          p={6}
          mb={10}
          width="530px"
          boxShadow="0px 7px 50px rgba(232, 242, 241, 0.8)"
        >
          <Box>
            <HStack align="flex-start" justify="space-between">
              <Box>
                <Heading fontSize="xl" color="teal.700" fontWeight="400">
                  Technical Works
                </Heading>
                <Box
                  mt={3}
                  width="80px"
                  h="3px"
                  backgroundColor="#4ECDC4"
                ></Box>
              </Box>
              <Text
                textDecoration="underline"
                color="#1A535C"
                onClick={() => removeAll()}
              >
                clear fields
              </Text>
            </HStack>
          </Box>

          <Stack spacing={6} py={4}>
            {technical.map((each, index) => (
              <>
                <Divider />
                <DeleteSection onClick={() => remove(index)} />
                <Input
                  py={6}
                  type="text"
                  name="authors"
                  aria-label="Authors"
                  value={getter('authors', index)}
                  onChange={handleInputChangeForIndex(index)}
                  placeholder="Authors"
                />

                <Input
                  py={6}
                  type="text"
                  name="title"
                  aria-label="Title"
                  value={getter('title)', index)}
                  onChange={handleInputChangeForIndex(index)}
                  placeholder="Title"
                />
                <Input
                  py={6}
                  type="text"
                  name="other"
                  aria-label="Other"
                  value={getter('other', index)}
                  onChange={handleInputChangeForIndex(index)}
                  placeholder="Other"
                />
                <HStack>
                  <Input
                    py={6}
                    type="text"
                    name="position"
                    aria-label="Position"
                    value={getter('position', index)}
                    onChange={handleInputChangeForIndex(index)}
                    placeholder="Position"
                  />
                  <Input
                    py={6}
                    type="text"
                    name="country"
                    aria-label="Country"
                    value={getter('country', index)}
                    onChange={handleInputChangeForIndex(index)}
                    placeholder="Country"
                  />
                </HStack>
                <HStack>
                  <Input
                    py={6}
                    type="number"
                    name="year"
                    aria-label="Year"
                    value={getter('year', index)}
                    onChange={handleInputChangeForIndex(index)}
                    placeholder="Year"
                  />
                  <Input
                    py={6}
                    type="text"
                    name="contribution"
                    aria-label="Contribution"
                    value={getter('contribution', index)}
                    onChange={handleInputChangeForIndex(index)}
                    placeholder="Contribution"
                  />
                </HStack>
                <Input
                  py={6}
                  type="text"
                  name="publication_url"
                  aria-label="Link to Publication Online"
                  placeholder="Link to Publication Online"
                  value={getter('publication_url', index)}
                  onChange={handleInputChangeForIndex(index)}
                />
              </>
            ))}
            <Button
              py={6}
              px={6}
              fontWeight={400}
              onClick={addNew}
              variant="outline"
              colorScheme="teal"
              borderStyle="dashed"
            >
              <Icon as={IoIosAddCircleOutline} w={6} h={6} marginRight={3} />
              Add another Technical Report
            </Button>
          </Stack>
        </Box>
      </Flex>
    </>
  );
};

export default Technical;
