import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  Heading,
  Image,
  Input,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router';
import UI from '../assets/images/image.png';
import { axiosFactory } from '../utils/axios';
import { usePostHook } from '../utils/request';
import { Link as RRLink } from 'react-router-dom';

// TODO: Uncomment forgot password link

const Login = () => {
  const { push } = useHistory();

  const { makeRequest, error } = usePostHook(axiosFactory(), '/user/login');
  const {
    handleSubmit,
    handleChange,
    handleReset,
    values: formValues,
    isSubmitting,
  } = useFormik({
    initialValues: { email: '', password: '' },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const { data } = await makeRequest({
          username: values.email,
          password: values.password,
        });
        localStorage.setItem('token', data.data.token);
        localStorage.setItem('user_data', JSON.stringify(data.data));
        push('/dashboard/');
      } catch (error) {
        console.error(error.message);
      }
    },
  });

  return (
    <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }}>
      <Flex
        p={10}
        flex={0.36}
        flexDirection="column"
        alignItems="flex-start"
        backgroundColor="#267D77"
        justifyContent="space-between"
      >
        <RRLink to="/" spacing={6}>
          <Image src={UI} boxSize="3rem" alt="university of ibadan" />
        </RRLink>
        <Stack color="white" spacing={8} py={10}>
          <Heading fontSize="2xl">Welcome back!</Heading>
          <Text fontSize="md" color="white">
            You may be prompted to change your password for security reasons.
          </Text>
          <Text fontSize="md" color="white">
            Don’t fret, your information is safe with us.
          </Text>
        </Stack>
        <Flex alignItems="center" display={{ base: 'none', md: 'flex' }}>
          <ArrowBackIcon w={5} h={5} marginRight={4} color="#FFD81E" />
          <Link href="/" color="white">
            Back to Homepage
          </Link>
        </Flex>
      </Flex>
      <form
        onSubmit={handleSubmit}
        style={{
          display: 'flex',
          flex: '1',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
        onReset={handleReset}
      >
        <Stack spacing={4} w={'100%'} maxW={'md'}>
          <Heading fontSize={'1.875rem'} textAlign="center">
            Log Into Your Account
          </Heading>
          {error ? (
            <Text textAlign="center" color="red.400">
              Invalid Credentials{' '}
            </Text>
          ) : (
            <Text textAlign="center">Input your details to login. </Text>
          )}
          <Stack spacing={10}>
            <FormControl id="email" marginTop="2.5rem">
              <Input
                py={6}
                type="email"
                name="email"
                onChange={handleChange}
                value={formValues.email}
                aria-label="Email address"
                placeholder="Email Address"
              />
            </FormControl>
            <FormControl id="password">
              <Input
                py={6}
                name="password"
                onChange={handleChange}
                value={formValues.password}
                type="password"
                aria-label="Password"
                placeholder="Password"
                autoComplete="new-password"
              />
            </FormControl>
          </Stack>

          <Stack spacing={6}>
            <Box width="100%">
              <Button
                type="submit"
                py={8}
                width="full"
                marginTop={8}
                color="white"
                fontWeight="400"
                isLoading={isSubmitting}
                variant={'solid'}
                fontSize="1.125rem"
                colorScheme="green"
                fontFamily="Inter"
                backgroundColor="#267D77"
              >
                Login
              </Button>
            </Box>
            <Stack
              align={'start'}
              direction={{ base: 'column', sm: 'row' }}
              justify={'space-between'}
            >
              <Checkbox>Remember me</Checkbox>
              {/* <Link href="/forgot-password" color={'rgba(50, 117, 133, 1)'}>
                Forgot password?
              </Link> */}
            </Stack>
          </Stack>
        </Stack>
        <Text marginTop="10%" color="#267D7799">
          Dont have an account?{' '}
          <Link href="/signup" color="#267D77">
            Register
          </Link>
        </Text>
      </form>
    </Stack>
  );
};

export default Login;
