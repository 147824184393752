import useUserData from '../../../hooks/userData';

// converts a setter Function to an onchange handler
export const getOnChangeHandlerFromSetter = setter => e => {
  setter(e.target.name, e.target.value);
};

export const DownloadElementById = (elementId, filename) => {
  const { userData } = useUserData();
  const element = document.getElementById(elementId);
  if (!element) throw new Error('element cannot be found');
  if (!window.html2pdf) throw new Error('Pdf lib doest exist');
  return window.html2pdf(element, {
    filename: `${userData.firstname}'s CV`,
    image: {
      quality: 2,
      type: 'jpeg',
    },
    html2canvas: {
      scale: 3,
      letterRendering: true,
      scrollX: 0,
      scrollY: -window.scrollY,
    },
    pagebreak: { mode: [], avoid: '.break-avoid' },
    jsPDF: { unit: 'in', format: 'a4', orientation: 'p' },
  });
};
