import { Text } from '@chakra-ui/react';

const DeleteSection = ({ onClick }) => {
  return (
    <Text
      textDecoration="underline"
      color="#1A535C"
      onClick={onClick}
    >
      Remove
    </Text>
  );
};

export default DeleteSection;
