import '@fontsource/inter';

import React from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';

import './main.css';

import theme from './theme';

import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/SignUp';
import FormOne from './pages/cv';
import Dashboard from './pages/Dashboard';
import Calculator from './pages/Calculator';
import AssessorSubmissionPage from './pages/assessor';
import ForgotPassword from './pages/ForgotPassword';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/signup">
            <Signup />
          </Route>
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/login" component={Login} />
          <Route exact path="/dashboard">
            <Dashboard />
          </Route>
          <Route path="/dashboard/form-one">
            <FormOne />
          </Route>
          <Route
            path={'/dashboard/assessor/submit_review/:reviewId'}
            component={AssessorSubmissionPage}
          />
          <Route path="/calculator">
            <Calculator />
          </Route>
        </Switch>
      </Router>
    </ChakraProvider>
  );
}

export default App;
