import { Input } from '@chakra-ui/react';
import React from 'react';

const DateInput = ({ ...props }) => {
  const [inputType, setInputType] = React.useState('text');

  return (
    <Input
      py={6}
      {...props}
      type={inputType}
      onClick={() => setInputType('date')}
      onBlur={() => setInputType('text')}
    />
  );
};

export default DateInput;
