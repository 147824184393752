
export const PREFIXES = {
    book: 'book',
    articles: 'articles',
    research: 'research',
    teaching: 'teaching',
    technical: 'technical',
    monographs: 'monographs',
    scholarship: 'scholarship',
    book_chapters: 'book_chapters',
    editorial_work: 'editorial_work',
    accepted_books: 'accepted_books',
    research_focus: 'research_focus',
    work_experience: 'work_experience',
    personal_details: 'personal_details',
    accepted_articles: 'accepted_articles',
    patent_copyrights: 'patent_copyrights',
    major_conferences: 'major_conferences',
    honour_distinction: 'honour_distinction',
    university_education: 'university_education',
    accepted_book_chapters: 'accepted_book_chapters',
    conference_proceedings: 'conference_proceedings',
    academic_qualifications: 'academic_qualifications',
    professional_qualifications: 'professional_qualifications',
  };