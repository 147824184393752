import {
  Box,
  Flex,
  Icon,
  Text,
  Input,
  Stack,
  Button,
  Divider,
  HStack,
  Heading,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { IoIosAddCircleOutline } from 'react-icons/io';

import { WorkExperienceContext } from '../context';
import DeleteSection from '../preview/generic/delete';
import isEmpty from '../utils/isEmpty';
import { Field } from '../utils/special';
import { TeachingPreview } from './TeachingExperience';

export const ExperiencePreview = () => {
  return (
    <div className="cv-section">
      <div
        style={{
          display: 'flex',
        }}
      >
        <p>VIII.</p>
        <div className="two-column" style={{ paddingLeft: '18px' }}>
          <p className="underline">
            {' '}
            <Field> Details of Teaching/Working Experience:</Field>
          </p>{' '}
          <p></p>
        </div>
      </div>
      <WorkExperiencePreview />
      <TeachingPreview />
    </div>
  );
};

export const WorkExperiencePreview = () => {
  const { getter, workExperience } = useContext(WorkExperienceContext);
  return (
    <div className="cv-section cv-section-inner">
      <div
        style={{
          display: 'flex',
        }}
      >
        <div className="two-column" style={{ paddingLeft: '26px' }}>
          <p>
            (a)
            <span className="underline" style={{ marginLeft: '10px' }}>
              <Field>Working Experience:</Field>
            </span>
          </p>
          <p>{isEmpty(workExperience) && 'Nil'}</p>
        </div>
      </div>
      {!isEmpty(workExperience) && (
        <ol className="lower-roman">
          {workExperience.map((each, index) => (
            <li
              key={index}
              style={{
                marginLeft: '27px',
              }}
            >
              <div className="two-column" style={{ marginLeft: '10px' }}>
                <p>
                  <span>{getter('position', index)}</span>,{' '}
                  <span>{getter('location', index)}</span>
                </p>
                <p>
                  <span>
                    {getter('period_start', index)} -{' '}
                    {getter('period_end', index)}{' '}
                  </span>
                </p>
              </div>
            </li>
          ))}
        </ol>
      )}
    </div>
  );
};

const WorkExperience = () => {
  // eslint-disable-next-line
  const [data, setData] = React.useState({});

  const { getter, setter, workExperience, addNew, remove, removeAll } =
    useContext(WorkExperienceContext);

  const handleInputChangeForIndex = index => e => {
    setter(e.target.name, e.target.value, index);
  };

  return (
    <>
      <Flex
        mb={8}
        px={12}
        width="50%"
        height="100%"
        align="center"
        maxWidth="500px"
        direction="column"
        justify="space-between"
      >
        <Box
          p={6}
          mb={10}
          width="530px"
          boxShadow="0px 7px 50px rgba(232, 242, 241, 0.8)"
        >
          <Box>
            <HStack align="flex-start" justify="space-between">
              <Box>
                <Heading fontSize="xl" color="teal.700" fontWeight="400">
                  Work Experience
                </Heading>
                <Box
                  mt={3}
                  width="80px"
                  h="3px"
                  backgroundColor="#4ECDC4"
                ></Box>
              </Box>
              <Text
                textDecoration="underline"
                color="#1A535C"
                onClick={() => removeAll()}
              >
                clear fields
              </Text>
            </HStack>
          </Box>

          <Stack spacing={6} py={4}>
            {workExperience.map((each, index) => (
              <>
                <Divider />
                <DeleteSection onClick={() => remove(index)} />
                <Input
                  py={6}
                  type="text"
                  name="position"
                  aria-label="Position"
                  placeholder="Position"
                  value={getter('position', index)}
                  onChange={handleInputChangeForIndex(index)}
                />
                <Input
                  py={6}
                  type="text"
                  name="location"
                  aria-label="Location"
                  placeholder="Location"
                  value={getter('location', index)}
                  onChange={handleInputChangeForIndex(index)}
                />
                <HStack>
                  <Input
                    py={6}
                    type="text"
                    aria-label="Start"
                    name="period_start"
                    placeholder="Start?"
                    value={getter('period_start', index)}
                    onChange={handleInputChangeForIndex(index)}
                  />
                  <Input
                    py={6}
                    type="text"
                    name="period_end"
                    placeholder="End?"
                    value={getter('period_end', index)}
                    onChange={handleInputChangeForIndex(index)}
                  />
                </HStack>
              </>
            ))}
            <Button
              py={6}
              px={6}
              fontWeight={400}
              onClick={addNew}
              variant="outline"
              colorScheme="teal"
              borderStyle="dashed"
            >
              <Icon as={IoIosAddCircleOutline} w={6} h={6} marginRight={3} />
              Add another Work Experience
            </Button>
          </Stack>
        </Box>
      </Flex>
    </>
  );
};

export default WorkExperience;
