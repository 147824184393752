import { useEffect } from 'react';
import { useState } from 'react';
import {
  AcademicQualificationContext,
  ArticleContext,
  ArticlesAcceptedContext,
  BookChaptersAcceptedContext,
  BookChaptersContext,
  BooksAcceptedContext,
  BooksContext,
  ConferencesContext,
  EditorialWorkContext,
  FullScreenContext,
  HonourDistinctionContext,
  MajorConfContext,
  MonographContext,
  PatentCopyrightsContext,
  PersonalDetailsContext,
  ProfessionalQualificationsContext,
  ResearchContext,
  ResearchFocusContext,
  RevisedChaptersContext,
  ScholarshipContext,
  TeachingContext,
  TechnicalContext,
  UniversityEducationContext,
  WorkExperienceContext,
} from '../context';
import PreviewComponent, { FullscreenPreview } from '../preview';
import {
  useFormListContextHandlerFactory,
  useSingleFormContextHandlerFactory,
} from '../utils/abs';
import { PREFIXES } from '../utils/prefixes';

const ReusablePreview = ({ previewState, loading }) => {
  const globalStateTuple = useState(previewState ?? {});

  const [showFullScreen, setFullScreenActive] = useState(false);

  useEffect(() => {
    globalStateTuple[1](previewState ?? {});
  }, [previewState]);
  const {
    getter: getPersonalDetails,
    setter: setPersonalDetails,
    state: personalDetails,
  } = useSingleFormContextHandlerFactory(
    PREFIXES.personal_details,
    globalStateTuple
  );

  const {
    getter: getResearchFocus,
    setter: setResearchFocus,
    state: researchFocus,
  } = useSingleFormContextHandlerFactory(
    PREFIXES.research_focus,
    globalStateTuple
  );
  // accessor for university education
  // DUPLICATE FOR ANY MULTIPLE
  const {
    getter: universityEducationGetter,
    setter: universityEducationSetter,
    state: universityEducation,
    addNewItem: addNewUniversity,
    removeAll: removeAllUniversities,
    removeItem: removeUniversity,
  } = useFormListContextHandlerFactory(
    PREFIXES.university_education,
    globalStateTuple
  );

  // accessor for academic qualifications

  const {
    getter: academicQualificationGetter,
    setter: academicQualificationSetter,
    state: academicQualifications,
    addNewItem: addAcademicQualification,
    removeAll: removeAllAcademicQualificaitons,
    removeItem: removeAcademicQualification,
  } = useFormListContextHandlerFactory(
    PREFIXES.academic_qualifications,
    globalStateTuple
  );

  // accessor for books
  const {
    getter: bookGetter,
    setter: bookSetter,
    state: books,
    addNewItem: addNewBook,
    removeAll: removeAllBooks,
    removeItem: removeBook,
  } = useFormListContextHandlerFactory(PREFIXES.book, globalStateTuple);

  //accessor for Patent & Copyrights
  const {
    getter: patentGetter,
    setter: patentSetter,
    state: patentCopyrights,
    addNewItem: addNewPatent,
    removeAll: removeAllPatents,
    removeItem: removePatent,
  } = useFormListContextHandlerFactory(
    PREFIXES.patent_copyrights,
    globalStateTuple
  );

  //accessor for Scholarship
  const {
    getter: scholarshipGetter,
    setter: scholarshipSetter,
    state: scholarship,
    addNewItem: addNewScholarship,
    removeAll: removeAllScholarships,
    removeItem: removeScholarship,
  } = useFormListContextHandlerFactory(PREFIXES.scholarship, globalStateTuple);

  //accessor for Honour and Distinction
  const {
    getter: honourGetter,
    setter: honourSetter,
    state: honourDistinction,
    addNewItem: addNewHonour,
    removeAll: removeAllHonours,
    removeItem: removeHonour,
  } = useFormListContextHandlerFactory(
    PREFIXES.honour_distinction,
    globalStateTuple
  );

  //accessor for Work Experience
  const {
    getter: workExperienceGetter,
    setter: workExperienceSetter,
    state: workExperience,
    addNewItem: addNewWorkExperience,
    removeAll: removeAllWorkExperiences,
    removeItem: removeWorkExperience,
  } = useFormListContextHandlerFactory(
    PREFIXES.work_experience,
    globalStateTuple
  );

  // accessor for professional
  const {
    getter: articlesGetter,
    setter: articlesSetter,
    state: articles,
    addNewItem: addNewArticle,
    removeAll: removeAllArticles,
    removeItem: removeArticle,
  } = useFormListContextHandlerFactory(PREFIXES.articles, globalStateTuple);

  const {
    getter: articlesAcceptedGetter,
    setter: articlesAcceptedSetter,
    state: articlesAccepted,
    addNewItem: addNewArticleAccepted,
    removeAll: removeAllArticlesAccepted,
    removeItem: removeArticleAccepted,
  } = useFormListContextHandlerFactory(
    PREFIXES.accepted_articles,
    globalStateTuple
  );

  const {
    getter: editorialWorkGetter,
    setter: editorialWorkSetter,
    state: editorialWork,
    addNewItem: addNewEditorialWork,
    removeAll: removeAllEditorialWork,
    removeItem: removeEditorialWork,
  } = useFormListContextHandlerFactory(
    PREFIXES.editorial_work,
    globalStateTuple
  );
  // accessor for books accepted
  const {
    getter: professionalQualificationsGetter,
    setter: professionalQualificationsSetter,
    state: professional,
    addNewItem: addNewProfessional,
    removeAll: removeAllProfessionalQualifications,
    removeItem: removeProfessionalQualification,
  } = useFormListContextHandlerFactory(
    PREFIXES.professional_qualifications,
    globalStateTuple
  );

  // accessor for books chapters
  const {
    getter: bookChaptersGetter,
    setter: bookChaptersSetter,
    state: bookChapters,
    addNewItem: addNewBookChapter,
    removeAll: removeAllBookChapters,
    removeItem: removeBookChapter,
  } = useFormListContextHandlerFactory(
    PREFIXES.book_chapters,
    globalStateTuple
  );

  // accessor for books chapters accepted
  const {
    getter: bookChaptersAcceptedGetter,
    setter: bookChaptersAcceptedSetter,
    state: bookChaptersAccepted,
    addNewItem: addNewBookChapterAccepted,
    removeAll: removeAllBookChaptersAccepted,
    removeItem: removeBookChapterAccepted,
  } = useFormListContextHandlerFactory(
    PREFIXES.accepted_book_chapters,
    globalStateTuple
  );

  // accessor for books accepted
  const {
    getter: booksAcceptedGetter,
    setter: booksAcceptedSetter,
    state: booksAccepted,
    addNewItem: addNewBookAccepted,
    removeAll: removeAllBookAccepted,
    removeItem: removeBookAccepted,
  } = useFormListContextHandlerFactory(
    PREFIXES.accepted_books,
    globalStateTuple
  );
  // accessor for monographs
  const {
    getter: monographGetter,
    setter: monographSetter,
    state: monographs,
    addNewItem: addNewMonograph,
    removeAll: removeAllMonographs,
    removeItem: removeMonograph,
  } = useFormListContextHandlerFactory(PREFIXES.monographs, globalStateTuple);

  // accessor for Technical Reports
  const {
    getter: technicalGetter,
    setter: technicalSetter,
    state: technical,
    addNewItem: addNewTechnical,
    removeAll: removeAllTechnical,
    removeItem: removeTechnical,
  } = useFormListContextHandlerFactory(PREFIXES.technical, globalStateTuple);

  // accessor for Revised Chapters
  const {
    getter: revisedChaptersGetter,
    setter: revisedChaptersSetter,
    state: revisedChapters,
    addNewItem: addNewRevisedChapters,
    removeAll: removeAllRevisedChapters,
    removeItem: removeRevisedChapter,
  } = useFormListContextHandlerFactory(
    PREFIXES.revisedChapters,
    globalStateTuple
  );

  // accessor for research
  const {
    getter: researchGetter,
    setter: researchSetter,
    state: research,
    addNewItem: addNewResearch,
  } = useFormListContextHandlerFactory(PREFIXES.research, globalStateTuple);

  // accessor for Conference
  const {
    getter: conferenceGetter,
    setter: conferenceSetter,
    state: conference,
    addNewItem: addNewConference,
    removeAll: removeAllConference,
    removeItem: removeConference,
  } = useFormListContextHandlerFactory(
    PREFIXES.conference_proceedings,
    globalStateTuple
  );

  // accessor for Teaching
  const {
    state: teachingExperience,
    getter: teachingGetter,
    setter: teachingSetter,
    addNewItem: addNewTeaching,
    removeItem: removeTeaching,
    removeAll: removeAllTeaching,
  } = useFormListContextHandlerFactory(PREFIXES.teaching, globalStateTuple);

  //accessor for Major Conferences
  const {
    state: majorConf,
    getter: majorConfGetter,
    setter: majorConfSetter,
    addNewItem: addNewMajorConf,
    removeItem: removeMajorConf,
    removeAll: removeAllMajorConf,
  } = useFormListContextHandlerFactory(
    PREFIXES.major_conferences,
    globalStateTuple
  );

  return (
    <FullScreenContext.Provider
      value={{
        showFullScreen,
        toggleShowFullscreen: () =>
          setFullScreenActive(showFullScreen ? false : true),
      }}
    >
      <PersonalDetailsContext.Provider
        value={{
          personalDetails,
          setter: setPersonalDetails,
          getter: getPersonalDetails,
        }}
      >
        <UniversityEducationContext.Provider
          value={{
            universityEducation,
            setter: universityEducationSetter,
            getter: universityEducationGetter,
            addNew: addNewUniversity,
            remove: removeUniversity,
            removeAll: removeAllUniversities,
          }}
        >
          <AcademicQualificationContext.Provider
            value={{
              academicQualifications,
              setter: academicQualificationSetter,
              getter: academicQualificationGetter,
              addNew: addAcademicQualification,
              remove: removeAcademicQualification,
              removeAll: removeAllAcademicQualificaitons,
            }}
          >
            <BooksContext.Provider
              value={{
                books,
                setter: bookSetter,
                getter: bookGetter,
                addNew: addNewBook,
                remove: removeBook,
                removeAll: removeAllBooks,
              }}
            >
              <ProfessionalQualificationsContext.Provider
                value={{
                  professional,
                  setter: professionalQualificationsSetter,
                  getter: professionalQualificationsGetter,
                  addNew: addNewProfessional,
                  remove: removeProfessionalQualification,
                  removeAll: removeAllProfessionalQualifications,
                }}
              >
                <PatentCopyrightsContext.Provider
                  value={{
                    patentCopyrights,
                    setter: patentSetter,
                    getter: patentGetter,
                    addNew: addNewPatent,
                    remove: removePatent,
                    removeAll: removeAllPatents,
                  }}
                >
                  <ScholarshipContext.Provider
                    value={{
                      scholarship,
                      setter: scholarshipSetter,
                      getter: scholarshipGetter,
                      addNew: addNewScholarship,
                      remove: removeScholarship,
                      removeAll: removeAllScholarships,
                    }}
                  >
                    <HonourDistinctionContext.Provider
                      value={{
                        honourDistinction,
                        setter: honourSetter,
                        getter: honourGetter,
                        addNew: addNewHonour,
                        remove: removeHonour,
                        removeAll: removeAllHonours,
                      }}
                    >
                      <WorkExperienceContext.Provider
                        value={{
                          workExperience,
                          setter: workExperienceSetter,
                          getter: workExperienceGetter,
                          addNew: addNewWorkExperience,
                          remove: removeWorkExperience,
                          removeAll: removeAllWorkExperiences,
                        }}
                      >
                        <ArticlesAcceptedContext.Provider
                          value={{
                            articlesAccepted,
                            setter: articlesAcceptedSetter,
                            getter: articlesAcceptedGetter,
                            addNew: addNewArticleAccepted,
                            remove: removeArticleAccepted,
                            removeAll: removeAllArticlesAccepted,
                          }}
                        >
                          <ArticleContext.Provider
                            value={{
                              articles,
                              getter: articlesGetter,
                              setter: articlesSetter,
                              addNew: addNewArticle,
                              remove: removeArticle,
                              removeAll: removeAllArticles,
                            }}
                          >
                            <ResearchContext.Provider
                              value={{
                                research,
                                setter: researchSetter,
                                getter: researchGetter,
                                addNew: addNewResearch,
                              }}
                            >
                              <EditorialWorkContext.Provider
                                value={{
                                  editorialWork,
                                  getter: editorialWorkGetter,
                                  setter: editorialWorkSetter,
                                  addNew: addNewEditorialWork,
                                  remove: removeEditorialWork,
                                  removeAll: removeAllEditorialWork,
                                }}
                              >
                                <BooksAcceptedContext.Provider
                                  value={{
                                    booksAccepted,
                                    setter: booksAcceptedSetter,
                                    getter: booksAcceptedGetter,
                                    addNew: addNewBookAccepted,
                                    remove: removeBookAccepted,
                                    removeAll: removeAllBookAccepted,
                                  }}
                                >
                                  <BookChaptersContext.Provider
                                    value={{
                                      bookChapters,
                                      setter: bookChaptersSetter,
                                      getter: bookChaptersGetter,
                                      addNew: addNewBookChapter,
                                      remove: removeBookChapter,
                                      removeAll: removeAllBookChapters,
                                    }}
                                  >
                                    <BookChaptersAcceptedContext.Provider
                                      value={{
                                        bookChaptersAccepted,
                                        setter: bookChaptersAcceptedSetter,
                                        getter: bookChaptersAcceptedGetter,
                                        addNew: addNewBookChapterAccepted,
                                        remove: removeBookChapterAccepted,
                                        removeAll:
                                          removeAllBookChaptersAccepted,
                                      }}
                                    >
                                      <MonographContext.Provider
                                        value={{
                                          monographs,
                                          setter: monographSetter,
                                          getter: monographGetter,
                                          addNew: addNewMonograph,
                                          remove: removeMonograph,
                                          removeAll: removeAllMonographs,
                                        }}
                                      >
                                        <TechnicalContext.Provider
                                          value={{
                                            technical,
                                            setter: technicalSetter,
                                            getter: technicalGetter,
                                            addNew: addNewTechnical,
                                            remove: removeTechnical,
                                            removeAll: removeAllTechnical,
                                          }}
                                        >
                                          <RevisedChaptersContext.Provider
                                            value={{
                                              revisedChapters,
                                              setter: revisedChaptersSetter,
                                              getter: revisedChaptersGetter,
                                              addNew: addNewRevisedChapters,
                                              remove: removeRevisedChapter,
                                              removeAll:
                                                removeAllRevisedChapters,
                                            }}
                                          >
                                            <ConferencesContext.Provider
                                              value={{
                                                conference,
                                                setter: conferenceSetter,
                                                getter: conferenceGetter,
                                                addNew: addNewConference,
                                                remove: removeConference,
                                                removeAll: removeAllConference,
                                              }}
                                            >
                                              <TeachingContext.Provider
                                                value={{
                                                  teachingExperience,
                                                  setter: teachingSetter,
                                                  getter: teachingGetter,
                                                  addNew: addNewTeaching,
                                                  remove: removeTeaching,
                                                  removeAll: removeAllTeaching,
                                                }}
                                              >
                                                <ResearchFocusContext.Provider
                                                  value={{
                                                    researchFocus,
                                                    setter: setResearchFocus,
                                                    getter: getResearchFocus,
                                                  }}
                                                >
                                                  {' '}
                                                  <MajorConfContext.Provider
                                                    value={{
                                                      majorConf,
                                                      setter: majorConfSetter,
                                                      getter: majorConfGetter,
                                                      addNew: addNewMajorConf,
                                                      remove: removeMajorConf,
                                                      removeAll:
                                                        removeAllMajorConf,
                                                    }}
                                                  >
                                                    {!loading && (
                                                      <PreviewComponent
                                                        noDownload
                                                      />
                                                    )}
                                                    {showFullScreen && (
                                                      <FullscreenPreview />
                                                    )}
                                                  </MajorConfContext.Provider>
                                                </ResearchFocusContext.Provider>
                                              </TeachingContext.Provider>
                                            </ConferencesContext.Provider>
                                          </RevisedChaptersContext.Provider>
                                        </TechnicalContext.Provider>
                                      </MonographContext.Provider>
                                    </BookChaptersAcceptedContext.Provider>
                                  </BookChaptersContext.Provider>
                                </BooksAcceptedContext.Provider>
                              </EditorialWorkContext.Provider>
                            </ResearchContext.Provider>
                          </ArticleContext.Provider>
                        </ArticlesAcceptedContext.Provider>
                      </WorkExperienceContext.Provider>
                    </HonourDistinctionContext.Provider>
                  </ScholarshipContext.Provider>
                </PatentCopyrightsContext.Provider>
              </ProfessionalQualificationsContext.Provider>
            </BooksContext.Provider>
          </AcademicQualificationContext.Provider>
        </UniversityEducationContext.Provider>
      </PersonalDetailsContext.Provider>
    </FullScreenContext.Provider>
  );
};

export default ReusablePreview;
