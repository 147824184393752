import {
  Th,
  Td,
  Tr,
  Box,
  Icon,
  Text,
  Image,
  Table,
  Tbody,
  Thead,
  VStack,
  Button,
  HStack,
  FormLabel,
  Input,
  Heading,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { DownloadElementById } from './cv/utils';

import UI from '../assets/images/image.png';

import CalculatorRow from '../components/CalculatorRow';
import { FiDownloadCloud } from 'react-icons/fi';

const Calculator = () => {
  const [calculatorRows, setCalculatorRows] = useState([
    {
      id: 1,
      score: 0,
      position: 0,
      noOfAuthors: 0,
      contribution: 0,
      weightedScore: 0,
      publicationType: '',
    },
  ]);

  const setter = (value, index) => {
    let stateClone = calculatorRows;

    stateClone[`${index}`] = value;
    setCalculatorRows(stateClone);
  };

  const [state, setState] = useState({
    name: '',
    position: '',
    positionSought: '',
  });

  const [downloadMode, setDownloadMode] = React.useState(false);

  const handleDownloadClick = async e => {
    e.stopPropagation();
    setDownloadMode(true);
    window.scrollTo(0, 0);

    await DownloadElementById('CalculatorPreviewContainer');
    setDownloadMode(false);
  };

  const [weightedScores, setWeightedScores] = useState({});

  const acumulateWeigtedScore = scores =>
    Object.values(scores).reduce((acc, curr) => acc + curr, 0);

  const Preview = () => {
    return (
      <VStack
        top={20}
        spacing={6}
        minH="700px"
        height="fit-content"
        position="sticky"
      >
        <Box
          padding={6}
          minHeight="700px"
          fontFamily="serif"
          lineHeight="1.5"
          height="fit-content"
          fontSize="8px"
          flex="1 0 auto"
          border="1px solid #267D77"
          boxShadow="0px 8px 30px rgba(232, 242, 241, 0.8)"
        >
          <div
            style={{
              top: '0',
              display: 'flex',
              height: '660px',
              width: '720px',
              padding: '30px 0 30px 30px ',
              fontFamily: '"Times New Roman" !important',
              overflow: 'scroll',
              position: 'relative',
              fontSize: '25px',
              flexDirection: 'column',
            }}
            id="CalculatorPreviewContainer"
          >
            <div className="cv-section break-avoid">
              <Box
                mb={4}
                fontSize="sm"
                fontWeight="bold"
                textTransform="uppercase"
              >
                TABLE FOR SCORES OF PUBLICATIONS FOR {state.name} FOR 2020
                PROMOTION FROM THE GRADE OF {state.position} TO{' '}
                {state.positionSought}
              </Box>
              <Table>
                <Thead>
                  <Tr>
                    <Th className="calc-cell-header" p={2}>
                      S/N
                    </Th>
                    <Th className="calc-cell-header" p={2}>
                      Publication Type
                    </Th>
                    <Th className="calc-cell-header" p={2}>
                      Position
                    </Th>
                    <Th className="calc-cell-header" p={2}>
                      No of Authors
                    </Th>
                    <Th className="calc-cell-header" p={2}>
                      % Contribution
                    </Th>
                    <Th className="calc-cell-header" p={2}>
                      Contribution in Fraction
                    </Th>
                    <Th className="calc-cell-header" p={2}>
                      Score Obtained
                    </Th>
                    <Th className="calc-cell-header" p={2}>
                      Max. Score Obtainable
                    </Th>
                    <Th className="calc-cell-header" p={2} isNumeric>
                      Weighted Score
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {calculatorRows.map((value, index) => (
                    <Tr key={index}>
                      <Td isNumeric className="calc-cell" key={index}>
                        {value.id}
                      </Td>
                      <Td className="calc-cell" key={index}>
                        {value.publicationType}
                      </Td>
                      <Td isNumeric className="calc-cell" key={index}>
                        {value.position}
                      </Td>
                      <Td isNumeric className="calc-cell" key={index}>
                        {value.noOfAuthors}
                      </Td>
                      <Td isNumeric className="calc-cell" key={index}>
                        {value.contribution}
                      </Td>
                      <Td isNumeric className="calc-cell" key={index}>
                        {value.contribution / 100}
                      </Td>
                      <Td isNumeric className="calc-cell" key={index}>
                        {value.score}
                      </Td>
                      <Td isNumeric className="calc-cell" key={index}>
                        {value.maxScore}
                      </Td>
                      <Td isNumeric className="calc-cell" key={index}>
                        {weightedScores[index]}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </div>
          </div>
        </Box>
      </VStack>
    );
  };

  return (
    <Box as="main" minHeight="100vh">
      <Box backgroundColor="#267D77" paddingBottom="8rem">
        <HStack
          p={4}
          as="nav"
          width="100%"
          margin="auto"
          maxWidth="1312px"
          justifyContent="space-between"
        >
          <Link to="/">
            <HStack spacing={2}>
              <Image src={UI} boxSize="2.5rem" alt="university of ibadan" />
            </HStack>
          </Link>

          <HStack display="none" spacing={4}>
            <Button
              as="a"
              px={6}
              href="/signup"
              py="0.625rem"
              height="100%"
              fontSize="1rem"
              color="#1A535C"
              borderRadius="none"
              backgroundColor="#FFD81E"
              _hover={{
                backgroundColor: '#FFE877',
              }}
            >
              Sign up
            </Button>
            <Button
              as="a"
              px={6}
              href="/login"
              py="0.625rem"
              height="100%"
              color="white"
              variant="outline"
              borderRadius="none"
              _hover={{
                color: '#FFD81E',
                border: '1px solid #FFD81E',
              }}
            >
              Login
            </Button>
          </HStack>
        </HStack>

        <VStack
          px={4}
          spacing={8}
          width="100%"
          color="white"
          margin="auto"
          maxWidth="932px"
          paddingTop="10%"
          textAlign="center"
        >
          <Heading fontSize={{ base: '3xl', md: '5xl' }}>
            ASSESSOR CALCULATOR
          </Heading>
          <Box mt={3} width="270px" h="3px" backgroundColor="#4ECDC4"></Box>
        </VStack>
      </Box>

      <Box
        py={16}
        mx="auto"
        px={'114px'}
        width="100%"
        marginTop="-24"
        minHeight="400px"
        maxWidth="1600px"
        backgroundColor="#FFFFFF"
        boxShadow="0px 56px 50px rgba(232, 242, 241, 0.62)"
      >
        <HStack spacing={8} mb={12}>
          <VStack alignItems="flex-start" width="100%">
            <FormLabel>Name</FormLabel>
            <Input
              py={6}
              value={state.name}
              placeholder="John Doe"
              onChange={event =>
                setState(prev => ({ ...prev, name: event.target.value }))
              }
            />
          </VStack>

          <VStack alignItems="flex-start" width="100%">
            <FormLabel>Position</FormLabel>
            <Input
              py={6}
              value={state.position}
              placeholder="Lecturer II"
              onChange={event =>
                setState(prev => ({
                  ...prev,
                  position: event.target.value,
                }))
              }
            />
          </VStack>

          <VStack alignItems="flex-start" width="100%">
            <FormLabel>Position Sought after</FormLabel>
            <Input
              py={6}
              value={state.positionSought}
              placeholder="Lecturer I"
              onChange={event =>
                setState(prev => ({
                  ...prev,
                  positionSought: event.target.value,
                }))
              }
            />
          </VStack>
        </HStack>

        <Table>
          <Thead>
            <Tr>
              <Th>S/N</Th>
              <Th>Publication Type</Th>
              <Th>No of Authors</Th>
              <Th>Position</Th>
              <Th>% Contribution</Th>
              <Th>Contribution in Fraction</Th>
              <Th>Max. Score Obtainable</Th>
              <Th>Score Obtained</Th>
              <Th isNumeric>Weighted Score</Th>
            </Tr>
          </Thead>
          <Tbody>
            {calculatorRows.map((row, index) => (
              <CalculatorRow
                globalWeightedScore={weightedScores}
                setGlobalWeightedScore={setWeightedScores}
                key={row.id}
                index={index}
                rowData={calculatorRows}
                updateRowData={setter}
              />
            ))}
          </Tbody>
        </Table>

        <Button
          width="100%"
          my="2rem"
          mx="auto"
          py={6}
          px={6}
          fontWeight={400}
          onClick={() =>
            setCalculatorRows(prevState => [
              ...prevState,
              {
                id: prevState.length + 1,
                score: 0,
                position: 0,
                noOfAuthors: 0,
                contribution: 0,
                weightedScore: 0,
                publicationType: '',
              },
            ])
          }
          variant="outline"
          colorScheme="teal"
          borderStyle="dashed"
        >
          <Icon as={IoIosAddCircleOutline} w={6} h={6} marginRight={3} />
          Add Publication
        </Button>

        <Box textAlign="right" background="#267D77" color="white" p={6}>
          <Text>
            Total Weighted Score: {acumulateWeigtedScore(weightedScores)}
          </Text>
        </Box>

        <Button
          width="100%"
          my="2rem"
          mx="auto"
          py={6}
          px={6}
          variant="solid"
          onClick={handleDownloadClick}
          colorScheme="teal"
          borderRadius="none"
          isLoading={downloadMode}
          rightIcon={<FiDownloadCloud />}
        >
          Download
        </Button>
      </Box>

      <Preview />
    </Box>
  );
};

export default Calculator;
