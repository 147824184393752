const SectionHeader = ({ value, underline }) => {
  return (
    <div
      style={{
        fontSize: '.625rem',
        marginBottom: '4px',
        marginTop:"20px",
        textDecoration: underline ? 'underline' : 'none',
      }}
    >
      {value}
    </div>
  );
};

SectionHeader.defaultProps = {
  underline: true,
};

export default SectionHeader;
