import React from 'react';
import {
  Box,
  Text,
  Menu,
  Image,
  Modal,
  VStack,
  HStack,
  Heading,
  MenuItem,
  MenuList,
  ListItem,
  ModalBody,
  IconButton,
  MenuButton,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  UnorderedList,
  useDisclosure,
  ModalCloseButton,
  Input,
  Button,
  Wrap,
  WrapItem,
  Center,
  Badge,
  Tag,
} from '@chakra-ui/react';
import { AiOutlineMore } from 'react-icons/ai';
import { FiLoader, FiThumbsUp, FiUsers } from 'react-icons/fi';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

import CVPreview from '../assets/svgs/cv-preview.svg';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { useAsyncGet, usePutHook } from '../utils/request';
import { axiosFactory } from '../utils/axios';
import axios from 'axios';
import { AuthGuard } from '../utils/guard';
import useUserData from '../hooks/userData';

const ResumeCard = ({ resumeDetails }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isAssignmentOpen, setAssignmentOpen] = useState(false);
  const history = useHistory();
  const [assessorOptions, setAssessorOptions] = useState([]);
  const [selectedAssessors, setSelectedAssessors] = useState([]);
  const ownerDetails = resumeDetails.cvSubmission.cvState.owner;

  const { makeRequest, loading } = usePutHook(
    axiosFactory(),
    `/cv/reviewer/cv_reviews/${resumeDetails.id}/assign`,
    {
      guards: [AuthGuard(history)],
    }
  );

  const handleChange = async e => {
    const { data } = await axiosFactory().get('/cv/assessor/search', {
      params: {
        username: e.target.value,
      },
    });
    setAssessorOptions(data.data.assessors);
  };

  const handleAssignment = async () => {
    await makeRequest({ assessorIds: selectedAssessors });
    setAssignmentOpen(false);
  };

  const handleSelection = each => () => {
    if (!selectedAssessors.includes(each.id)) {
      setSelectedAssessors([...selectedAssessors, each.id]);
    } else {
      setSelectedAssessors([...selectedAssessors.filter(a => a !== each.id)]);
    }
  };
  return (
    <>
      <Box height="200px" width="180px">
        <Box>
          <Image w="100%" src={CVPreview} alt="" />
        </Box>
        <Box
          style={{
            marginTop: '-4px',
            border: '1px solid rgba(50, 117, 133, 0.2)',
          }}
          p={2}
        >
          <HStack justifyContent="space-between">
            <Text>
              {ownerDetails.firstname}'s CV
              <Text fontSize={'small'} color={'GrayText'}>
                {formatDistanceToNow(new Date(resumeDetails.createdAt), {
                  addSuffix: true,
                  includeSeconds: true,
                })}
              </Text>
            </Text>
            <Menu>
              <MenuButton
                as={IconButton}
                variant="ghost"
                aria-label="Options"
                icon={<AiOutlineMore />}
              />
              <MenuList>
                {resumeDetails.assessorReviews.length !== 3 && (
                  <MenuItem
                    fontSize="md"
                    icon={<FiUsers />}
                    onClick={() => setAssignmentOpen(true)}
                  >
                    Assign assessor
                  </MenuItem>
                )}
                <MenuItem onClick={onOpen} fontSize="md" icon={<FiLoader />}>
                  Check Status
                </MenuItem>
                <MenuItem fontSize="md" icon={<FiThumbsUp />}>
                  Review
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </Box>
      </Box>
      <Modal
        size="2xl"
        isOpen={isAssignmentOpen}
        onClose={() => setAssignmentOpen(false)}
      >
        <ModalOverlay />
        <ModalContent spacing="8">
          <ModalHeader textAlign="center">
            <VStack>
              <Heading mt={8} fontSize="xl">
                Assign Assessor to {ownerDetails.firstname}'s CV
              </Heading>
              <Box mt={3} h="3px" backgroundColor="#4ECDC4" width="80px"></Box>
            </VStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Text fontSize={'small'} color={'GrayText'}>
                {selectedAssessors.length
                  ? `${selectedAssessors.length} assessors selected`
                  : ''}
              </Text>
              <Input
                onChange={handleChange}
                placeholder="Search For assessor ( email or phone number )"
              />
            </Box>
            <Box marginTop={'20px'}>
              <Wrap>
                {assessorOptions.map((each, i) => (
                  <WrapItem>
                    <Center
                      onClick={handleSelection(each)}
                      borderRadius={'10px'}
                      color={
                        selectedAssessors.includes(each.id) ? 'teal' : 'white'
                      }
                      border={
                        selectedAssessors.includes(each.id)
                          ? '3px solid teal'
                          : ''
                      }
                      fontWeight={'bold'}
                      w="180px"
                      h="40px"
                      bg={
                        selectedAssessors.includes(each.id) ? 'white' : 'teal'
                      }
                    >
                      {each.lastname}
                    </Center>
                  </WrapItem>
                ))}
              </Wrap>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={handleAssignment}
              isLoading={loading}
              disabled={!selectedAssessors.length}
            >
              Assign
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent spacing="8">
          <ModalHeader textAlign="center">
            <VStack>
              <Heading mt={8} fontSize="xl">
                {ownerDetails.firstname}'s CV Status
              </Heading>
              <Box mt={3} h="3px" backgroundColor="#4ECDC4" width="80px"></Box>
            </VStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <HStack></HStack>
            </Box>
            <UnorderedList spacing={6} my={16}>
              <ListItem>
                <strong>Submitted:</strong> CV has been submited to the
                department for assessment.
              </ListItem>
              <ListItem>
                <strong>At the faculty:</strong> CV has been escalated to the
                faculty level.
              </ListItem>
              <ListItem>
                <strong>At the university:</strong> CV has been escalated to the
                university level.
              </ListItem>
            </UnorderedList>
          </ModalBody>

          <ModalFooter
            justifyContent="flex-start"
            colorScheme="teal"
            backgroundColor="teal.700"
          >
            <Text color="white" mr={3} onClick={onClose}>
              Promotion Status: <strong>Pending</strong>
            </Text>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export const AssessorResume = ({ resumeDetails }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const ownerDetails = resumeDetails.cvReview.cvSubmission.cvState.owner;
  const history = useHistory();
  return (
    <>
      <Box height="200px" width="180px">
        <Box>
          <Image w="100%" src={CVPreview} alt="" />
        </Box>
        <Box
          style={{
            marginTop: '-4px',
            border: '1px inset rgba(50, 117, 133, 0.2) ',
          }}
          p={2}
        >
          <HStack justifyContent="space-between">
            <Text>
              {ownerDetails.firstname}'s CV
              <Text fontSize={'small'} color={'GrayText'}>
                {formatDistanceToNow(new Date(resumeDetails.createdAt), {
                  addSuffix: true,
                  includeSeconds: true,
                })}
              </Text>
            </Text>
            <Menu>
              <MenuButton
                as={IconButton}
                variant="ghost"
                aria-label="Options"
                icon={<AiOutlineMore />}
              />
              <MenuList>
                <MenuItem
                  onClick={() =>
                    history.push(
                      `/dashboard/assessor/submit_review/${resumeDetails.cvReview.id}`
                    )
                  }
                  fontSize="md"
                  icon={<FiThumbsUp />}
                >
                  Review
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </Box>
      </Box>
    </>
  );
};

export const CvSubmissionsResume = ({ resumeDetails }) => {
  const { userData } = useUserData();
  const [isOpen, setIsOpen] = useState();
  return (
    <>
      <Box height="200px" width="180px">
        <Image w="100%" src={CVPreview} alt="" />
        <Box
          style={{
            marginTop: '-4px',
            border: '1px solid rgba(50, 117, 133, 0.2)',
          }}
          p={2}
        >
          <HStack justifyContent="space-between" alignItems={'flex-start'}>
            <VStack alignItems={'flex-start'}>
              <Text style={{ textTransform: 'capitalize' }}>
                {userData.firstname}'s CV
                <Text fontSize={'x-small'} color={'GrayText'}>
                  Submitted{' '}
                  {formatDistanceToNow(new Date(resumeDetails.createdAt), {
                    addSuffix: true,
                    includeSeconds: true,
                  })}
                </Text>
              </Text>
              <Text fontSize="x-small">
                <Tag size="sm">Pending</Tag>
              </Text>
            </VStack>

            <Menu>
              <MenuButton
                as={IconButton}
                variant="ghost"
                aria-label="Options"
                icon={<AiOutlineMore />}
              />
              <MenuList>
                <MenuItem
                  fontSize="md"
                  icon={<FiThumbsUp />}
                  onClick={() => setIsOpen(true)}
                >
                  Track status
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
          <Modal size="2xl" isOpen={isOpen} onClose={() => setIsOpen(false)}>
            <ModalOverlay />
            <ModalContent spacing="8">
              <ModalHeader textAlign="center">
                <VStack>
                  <Heading mt={8} fontSize="xl">
                    Submission Status
                  </Heading>
                  <Box
                    mt={3}
                    h="3px"
                    backgroundColor="#4ECDC4"
                    width="80px"
                  ></Box>
                </VStack>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box>
                  <HStack></HStack>
                </Box>
                <UnorderedList spacing={6} my={16}>
                  <ListItem>
                    <strong>Submitted:</strong> CV has been submited to the
                    department for assessment.
                  </ListItem>
                  <ListItem>
                    <strong>At the faculty:</strong> CV has been escalated to
                    the faculty level.
                  </ListItem>
                  <ListItem>
                    <strong>At the university:</strong> CV has been escalated to
                    the university level.
                  </ListItem>
                </UnorderedList>
              </ModalBody>

              <ModalFooter
                justifyContent="flex-start"
                colorScheme="teal"
                backgroundColor="teal.700"
              >
                <Text color="white" mr={3}>
                  Promotion Status: <strong>Pending</strong>
                </Text>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Box>
      </Box>
    </>
  );
};

export default ResumeCard;
