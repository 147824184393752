import React from 'react';

// Personal Details context
export const PersonalDetailsContext = React.createContext({
  personalDetails: {},
  setter: (attribute, value) => {},
  getter: attribute => {},
});

export const UniversityEducationContext = React.createContext({
  universityEducation: [],
  setter: (attribute, value, index) => {},
  getter: (attribute, index) => {},
  addNew: () => {},
  remove: index => {},
  removeAll: () => {},
});

export const AcademicQualificationContext = React.createContext({
  academicQualifications: [],
  setter: (attribute, value, index) => {},
  getter: (attribute, index) => {},
  addNew: () => {},
  remove: index => {},
  removeAll: () => {},
});

export const BookChaptersContext = React.createContext({
  bookChapters: [],
  setter: (attribute, value, index) => {},
  getter: (attribute, index) => {},
  addNew: () => {},
  remove: index => {},
  removeAll: () => {},
});

export const BookChaptersAcceptedContext = React.createContext({
  bookChaptersAccepted: [],
  setter: (attribute, value, index) => {},
  getter: (attribute, index) => {},
  addNew: () => {},
  remove: index => {},
  removeAll: () => {},
});

export const BooksContext = React.createContext({
  books: [],
  setter: (attribute, value, index) => {},
  getter: (attribute, index) => {},
  addNew: () => {},
  remove: index => {},
  removeAll: () => {},
});

export const ArticleContext = React.createContext({
  articles: [],
  setter: (attribute, value, index) => {},
  getter: (attribute, index) => {},
  addNew: () => {},
  remove: index => {},
  removeAll: () => {},
});
export const PatentCopyrightsContext = React.createContext({
  patentCopyrights: [],
  setter: (attribute, value, index) => {},
  getter: (attribute, index) => {},
  addNew: () => {},
  remove: index => {},
  removeAll: () => {},
});

export const ArticlesAcceptedContext = React.createContext({
  articlesAccepted: [],
  setter: (attribute, value, index) => {},
  getter: (attribute, index) => {},
  addNew: () => {},
  remove: index => {},
  removeAll: () => {},
});
export const ScholarshipContext = React.createContext({
  scholarship: [],
  setter: (attribute, value, index) => {},
  getter: (attribute, index) => {},
  addNew: () => {},
  remove: index => {},
  removeAll: () => {},
});

export const EditorialWorkContext = React.createContext({
  editorialWork: [],
  setter: (attribute, value, index) => {},
  getter: (attribute, index) => {},
  addNew: () => {},
  remove: index => {},
  removeAll: () => {},
});

export const HonourDistinctionContext = React.createContext({
  honourDistinction: [],
  setter: (attribute, value, index) => {},
  getter: (attribute, index) => {},
  addNew: () => {},
  remove: index => {},
  removeAll: () => {},
});

export const BooksAcceptedContext = React.createContext({
  booksAccepted: [],
  setter: (attribute, value, index) => {},
  getter: (attribute, index) => {},
  addNew: () => {},
  remove: index => {},
  removeAll: () => {},
});
export const WorkExperienceContext = React.createContext({
  workExperience: [],
  setter: (attribute, value, index) => {},
  getter: (attribute, index) => {},
  addNew: () => {},
  remove: index => {},
  removeAll: () => {},
});

export const ProfessionalQualificationsContext = React.createContext({
  professional: [],
  setter: (attribute, value, index) => {},
  getter: (attribute, index) => {},
  addNew: () => {},
  remove: index => {},
  removeAll: () => {},
});

export const ResearchContext = React.createContext({
  research: [],
  setter: (attribute, value, index) => {},
  getter: (attribute, index) => {},
  addNew: () => {},
});

export const MonographContext = React.createContext({
  monographs: [],
  setter: (attribute, value, index) => {},
  getter: (attribute, index) => {},
  addNew: () => {},
  remove: index => {},
  removeAll: () => {},
});

export const TechnicalContext = React.createContext({
  technical: [],
  setter: (attribute, value, index) => {},
  getter: (attribute, index) => {},
  addNew: () => {},
  remove: index => {},
  removeAll: () => {},
});

export const RevisedChaptersContext = React.createContext({
  revisedChapters: [],
  setter: (attribute, value, index) => {},
  getter: (attribute, index) => {},
  addNew: () => {},
  remove: index => {},
  removeAll: () => {},
});

export const ConferencesContext = React.createContext({
  conference: [],
  setter: (attribute, value, index) => {},
  getter: (attribute, index) => {},
  addNew: () => {},
  remove: index => {},
  removeAll: () => {},
});

export const TeachingContext = React.createContext({
  teachingExperience: [],
  setter: (attribute, value, index) => {},
  getter: (attribute, index) => {},
  addNew: () => {},
  remove: index => {},
  removeAll: () => {},
});

export const ResearchFocusContext = React.createContext({
  researchFocus: '',
  setter: (attribute, value) => {},
  getter: attribute => {},
});

export const PositionSoughtContext = React.createContext({
  positionSought: '',
  setter: (attribute, value) => {},
  getter: attribute => {},
});

export const MajorConfContext = React.createContext({
  conferences: [],
  setter: (attribute, value, index) => {},
  getter: (attribute, index) => {},
  addNew: () => {},
  remove: index => {},
  removeAll: () => {},
});

export const Calculator = React.createContext({
  rows: [],
  setter: (attribute, value, index) => {},
  getter: (attribute, index) => {},
  addNew: () => {},
  remove: index => {},
  removeAll: () => {},
});

// Micscelanour

export const FullScreenContext = React.createContext({
  showFullScreen: false,
  currentStep: 0,
  toggleShowFullscreen: () => null,
  nextStep: () => null,
  prevStep: () => null,
});

