import { useEffect, useState } from 'react';

export const useGetHook = (instance, url, config) => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(null);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const fetch = async () => {
    setLoading(true);
    try {
      const response = await instance.get(url, { params: config?.params });
      if (config?.guards && config.guards.length !== 0) {
        await Promise.all(
          config.guards.map(async eachGuard => eachGuard(response, null))
        );
      }
      setData(response.data);
      setResponse(response);
    } catch (err) {
      if (config?.guards && config.guards.length !== 0) {
        await Promise.all(
          config.guards.map(async eachGuard => eachGuard(null, err))
        );
      }
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch();
    return () => {
      setError(null);
      setData(null);
    };
  }, [config?.params]);

  return { loading, response, data, error, refetch: fetch };
};

export const usePostHook = (instance, url, config) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const makeRequest = async body => {
    setLoading(true);
    try {
      const response = await instance.post(url, body, {
        params: config?.params ?? {},
        headers: { 'Content-Type': 'application/json' },
      });

      // run guards
      if (config?.guards && config.guards.length !== 0) {
        await Promise.all(
          config.guards.map(async eachGuard => eachGuard(response, null))
        );
      }
      setData(response.data);
      setLoading(false);
      return response;
    } catch (error) {
      // run guards
      console.log(error);
      if (config?.guards && config.guards.length !== 0) {
        await Promise.all(
          config.guards.map(async eachGuard => eachGuard(null, error))
        );
      }
      setError(error);
      setLoading(false);
      throw error;
    }
  };

  return { loading, data, error, makeRequest };
};

export const usePutHook = (instance, url, config) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const makeRequest = async body => {
    setLoading(true);
    try {
      const response = await instance.put(url, body, {
        params: config?.params ?? {},
        headers: { 'Content-Type': 'application/json' },
      });

      // run guards
      if (config?.guards && config.guards.length !== 0) {
        await Promise.all(
          config.guards.map(async eachGuard => eachGuard(response, null))
        );
      }
      setData(response.data);
      setLoading(false);
      return response;
    } catch (error) {
      // run guards
      console.log(error);
      if (config?.guards && config.guards.length !== 0) {
        await Promise.all(
          config.guards.map(async eachGuard => eachGuard(null, error))
        );
      }
      setError(error);
      setLoading(false);
      throw error;
    }
  };

  return { loading, data, error, makeRequest };
};

export const useAsyncGet = (instance, url, config) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const makeRequest = async body => {
    setLoading(true);
    try {
      const response = await instance.get(url, {
        params: config?.params ?? {},
      });

      // run guards
      if (config?.guards && config.guards.length !== 0) {
        await Promise.all(
          config.guards.map(async eachGuard => eachGuard(response, null))
        );
      }
      setData(response.data);
      setLoading(false);
      return response;
    } catch (error) {
      // run guards
      if (config?.guards && config.guards.length !== 0) {
        await Promise.all(
          config.guards.map(async eachGuard => eachGuard(null, error))
        );
      }
      setError(error);
      setLoading(false);
      throw error;
    }
  };

  return { loading, data, error, makeRequest };
};

export const useFormDataPostHook = (instance, url, config) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const makeRequest = async body => {
    setLoading(true);
    try {
      const response = await instance.post(url, body, {
        params: config?.params ?? {},
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // run guards
      if (config?.guards && config.guards.length !== 0) {
        await Promise.all(
          config.guards.map(async eachGuard => eachGuard(response, null))
        );
      }
      setData(response.data);
      setLoading(false);
      return response;
    } catch (error) {
      // run guards
      if (config?.guards && config.guards.length !== 0) {
        await Promise.all(
          config.guards.map(async eachGuard => eachGuard(null, error))
        );
      }
      setError(error);
      setLoading(false);
      throw error;
    }
  };

  return { loading, data, error, makeRequest };
};
