import {
  Box,
  Text,
  Link,
  Grid,
  Image,
  Input,
  HStack,
  VStack,
  Center,
  Avatar,
  Heading,
  WrapItem,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import React from 'react';
import { useHistory } from 'react-router';
import { AddIcon, SearchIcon } from '@chakra-ui/icons';
import CVPreview from '../assets/svgs/cv-preview.svg';

import UI from '../assets/images/image.png';
import FS from '../assets/images/image.png';
import ResumeCard, {
  AssessorResume,
  CvSubmissionsResume,
} from '../components/ResumeCard';

import { AuthGuard } from '../utils/guard';
import useUserData from '../hooks/userData';
import { axiosFactory } from '../utils/axios';
import { useGetHook, useAsyncGet } from '../utils/request';
import CvHeader from '../components/CvHeader';
import { formatDistanceToNow } from 'date-fns';

const testData = [
  {
    id: 5,
    createdAt: '2021-11-21T20:55:03.163Z',
    updatedAt: '2021-11-21T20:55:03.185Z',
    reviewerCompletedAt: null,
    reviewer: {
      id: 2,
      firstname: 'reviewer_staff',
      middlename: 'a',
      lastname: 'reviewer_staff',
      email: 'reviewer_staff@gmail.com',
      phoneNo: '08128730171',
      gender: 'M',
      department: 'Computer Science',
      blacklisted: false,
    },
    assessorReviews: [],
    reviewerStatus: {
      id: 8,
      description: 'Awaiting Departmental Approval',
      code: 'cv.pending_departmental_approval',
      state: 'pending',
    },
  },
  {
    id: 10,
    createdAt: '2021-11-21T21:19:46.092Z',
    updatedAt: '2021-11-21T21:19:46.092Z',
    reviewerCompletedAt: null,
    reviewer: {
      id: 2,
      firstname: 'reviewer_staff',
      middlename: 'a',
      lastname: 'reviewer_staff',
      email: 'reviewer_staff@gmail.com',
      phoneNo: '08128730171',
      gender: 'M',
      department: 'Computer Science',
      blacklisted: false,
    },
    assessorReviews: [],
    reviewerStatus: {
      id: 8,
      description: 'Awaiting Departmental Approval',
      code: 'cv.pending_departmental_approval',
      state: 'pending',
    },
  },
  {
    id: 12,
    createdAt: '2021-11-21T21:28:30.293Z',
    updatedAt: '2021-11-21T21:28:30.315Z',
    reviewerCompletedAt: null,
    reviewer: {
      id: 2,
      firstname: 'reviewer_staff',
      middlename: 'a',
      lastname: 'reviewer_staff',
      email: 'reviewer_staff@gmail.com',
      phoneNo: '08128730171',
      gender: 'M',
      department: 'Computer Science',
      blacklisted: false,
    },
    assessorReviews: [],
    reviewerStatus: {
      id: 8,
      description: 'Awaiting Departmental Approval',
      code: 'cv.pending_departmental_approval',
      state: 'pending',
    },
  },
  {
    id: 11,
    createdAt: '2021-11-21T21:21:46.897Z',
    updatedAt: '2021-11-21T21:21:46.912Z',
    reviewerCompletedAt: null,
    reviewer: {
      id: 2,
      firstname: 'reviewer_staff',
      middlename: 'a',
      lastname: 'reviewer_staff',
      email: 'reviewer_staff@gmail.com',
      phoneNo: '08128730171',
      gender: 'M',
      department: 'Computer Science',
      blacklisted: false,
    },
    assessorReviews: [],
    reviewerStatus: {
      id: 8,
      description: 'Awaiting Departmental Approval',
      code: 'cv.pending_departmental_approval',
      state: 'pending',
    },
  },
];

const Dashboard = () => {
  const history = useHistory();
  const [search, setSearch] = React.useState('');
  const [username, setUsername] = React.useState('');

  const { loading: loadingReviewerCvs, data: reviewerCvReviews } = useGetHook(
    axiosFactory(),
    '/cv/reviewer/cv_reviews',
    {
      guards: [AuthGuard(history)],
    }
  );

  const { loading: loadingAssessorReviews, data: assignedAssessorReviews } =
    useGetHook(axiosFactory(), '/cv/assessor/assigned_assessor_reviews', {
      guards: [AuthGuard(history)],
    });

  const { loading: loadingCvSubmissionsForUser, data: cvsSubmittedByUser } =
    useGetHook(axiosFactory(), '/cv/submissions', {
      guards: [AuthGuard(history)],
    });

  const { loading, data } = useGetHook(axiosFactory(), '/cv/get', {
    guards: [AuthGuard(history)],
  });

  // const status = useGetHook(axiosFactory(), '/statuses', {
  //   guards: [AuthGuard(history)],
  // });

  // if (!loading) {
  //   console.log(data.data);
  // }

  // console.log(status.data);
  const { userData, error } = useUserData();

  return (
    <main>
      <CvHeader />
      <Box py={8} px={6} width="100%" background="#F9FAFB">
        <Box
          width="100%"
          margin="auto"
          maxWidth="1312px"
          paddingBottom={'2rem'}
        >
          <Heading fontSize="xl">Your CVs</Heading>
          <Box mt={3} h="3px" backgroundColor="#4ECDC4" width="100px"></Box>
          <HStack my="2rem">
            {data && (
              <Box height="200px" width="160px">
                <Link href="/dashboard/form-one">
                  <VStack>
                    <Image src={CVPreview} />
                    <Text
                      sx={{ textTransform: 'capitalize', textAlign: 'center' }}
                    >
                      {!error && `${userData.firstname}'s CV`}
                      <Text fontSize={'x-small'} color="GrayText">
                        last updated{' '}
                        {formatDistanceToNow(new Date(data.data.updatedAt), {
                          addSuffix: true,
                          includeSeconds: true,
                        })}
                      </Text>
                    </Text>
                  </VStack>
                </Link>
              </Box>
            )}
          </HStack>
        </Box>
      </Box>
      <VStack px={6} spacing={12}>
        {cvsSubmittedByUser && cvsSubmittedByUser.data.length && (
          <Box width="100%" maxWidth="1312px">
            <Heading mt={8} fontSize="xl">
              Your Cv Submissions
            </Heading>
            <Box mt={3} h="3px" backgroundColor="#4ECDC4" width="100px"></Box>

            <Grid className="dashboard-grid" gap={1} py="1rem" spacing={6}>
              {cvsSubmittedByUser.data.map(eachData => (
                <CvSubmissionsResume resumeDetails={eachData} />
              ))}
            </Grid>
          </Box>
        )}
        {assignedAssessorReviews && assignedAssessorReviews.data.length && (
          <Box width="100%" margin="auto" marginTop={"20px"} maxWidth="1312px">
            <Heading mt={8} fontSize="xl">
              Pending Feedback As Assessor
            </Heading>
            <Box mt={3} h="3px" backgroundColor="#4ECDC4" width="100px"></Box>

            <Grid className="dashboard-grid" gap={12} py={"1rem"} spacing={6}>
              {assignedAssessorReviews &&
                assignedAssessorReviews.data.map(eachData => (
                  <AssessorResume resumeDetails={eachData} />
                ))}
            </Grid>
          </Box>
        )}
        {reviewerCvReviews && reviewerCvReviews.data.cvReviews.length && (
          <Box width="100%" margin="auto" marginTop={"20px"} maxWidth="1312px">
            <HStack mt={8} justifyContent="space-between">
              <Box>
                <Heading fontSize="xl">Assigned CV's</Heading>
                <Box
                  mt={3}
                  h="3px"
                  backgroundColor="#4ECDC4"
                  width="100px"
                ></Box>
              </Box>
              <InputGroup maxWidth="400px">
                <InputLeftElement
                  poInterEvents="none"
                  children={<SearchIcon color="gray.300" />}
                />
                <Input
                  aria-label="Search name of CV"
                  placeholder="Search name of CV"
                  onChange={e => setSearch(e.target.value)}
                />
              </InputGroup>
            </HStack>

            <Grid className="dashboard-grid" gap={2} my="2rem" spacing={6}>
              {reviewerCvReviews &&
                reviewerCvReviews.data.cvReviews.map(eachDetail => (
                  <ResumeCard resumeDetails={eachDetail} />
                ))}
            </Grid>
          </Box>
        )}
      </VStack>
    </main>
  );
};

export default Dashboard;
