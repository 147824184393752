import React from 'react';
import { Link, Image, Button, HStack } from '@chakra-ui/react';

import UI from '../assets/images/image.png';

const Nav = () => {
  return (
    <HStack
      px={4}
      pt={6}
      as="nav"
      width="100%"
      margin="auto"
      maxWidth="1312px"
      justifyContent="space-between"
    >
      <HStack spacing={6}>
        <Image src={UI} boxSize="3rem" alt="university of ibadan" />
      </HStack>
      <HStack
        spacing={100}
        fontSize="xl"
        color="#ffffff7f"
        justifyContent="center"
        display={{ base: 'none', md: 'flex' }}
      >
        <Link href="/" color="#FFD81E">
          Home
        </Link>
        <Link href="/calculator">Assessor Calculator</Link>
        <Link href="/about">About</Link>
      </HStack>
      <HStack spacing={4}>
        <Button
          as="a"
          px={6}
          href="/signup"
          py="0.625rem"
          height="100%"
          fontSize="1rem"
          color="#1A535C"
          borderRadius="none"
          backgroundColor="#FFD81E"
          _hover={{
            backgroundColor: '#FFE877',
          }}
        >
          Sign up
        </Button>
        <Button
          as="a"
          px={6}
          href="/login"
          py="0.625rem"
          height="100%"
          color="white"
          variant="outline"
          borderRadius="none"
          _hover={{
            color: '#FFD81E',
            border: '1px solid #FFD81E',
          }}
        >
          Login
        </Button>
      </HStack>
    </HStack>
  );
};

export default Nav;
