import {
  Box,
  Flex,
  Icon,
  Text,
  Input,
  Stack,
  Button,
  Divider,
  HStack,
  Heading,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { IoIosAddCircleOutline } from 'react-icons/io';

import { TeachingContext } from '../context';
import DeleteSection from '../preview/generic/delete';
import isEmpty from '../utils/isEmpty';
import { Field } from '../utils/special';

export const TeachingPreview = () => {
  const { getter, teachingExperience } = useContext(TeachingContext);
  return (
    <div className="cv-section cv-section-inner" style={{ marginTop: '22px' }}>
      <div
        style={{
          display: 'flex',
        }}
      >
        <div className="two-column" style={{ paddingLeft: '26px' }}>
          <p>
            (b)
            <span className="underline" style={{ marginLeft: '10px' }}>
              <Field>Teaching Experience:</Field>
            </span>
          </p>
          <p>{isEmpty(teachingExperience) && 'Nil'}</p>
        </div>
      </div>
      {!isEmpty(teachingExperience) && (
        <div style={{ paddingLeft: '16px' }}>
          <div
            style={{
              marginLeft: '27px',
            }}
          >
            <div
              className="three-column"
              style={{ marginLeft: '10px', marginTop: '12px' }}
            >
              <p>Course Code</p>
              <p>Course Title</p>
              <p>Course Units</p>
            </div>
          </div>
          {teachingExperience.map((each, index) => (
            <div
              key={index}
              style={{
                marginLeft: '27px',
              }}
            >
              <div className="three-column" style={{ marginLeft: '10px' }}>
                <p>{getter('code', index)}</p>
                <p>{getter('title', index)}</p>
                <p>{getter('units', index)}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const Teaching = () => {
  // eslint-disable-next-line
  const [data, setData] = React.useState({});

  const { getter, setter, teachingExperience, addNew, remove, removeAll } =
    useContext(TeachingContext);

  const handleInputChangeForIndex = index => e => {
    setter(e.target.name, e.target.value, index);
  };

  return (
    <>
      <Flex
        mb={8}
        px={12}
        width="50%"
        height="100%"
        align="center"
        maxWidth="500px"
        direction="column"
        justify="space-between"
      >
        <Box
          p={6}
          mb={10}
          width="530px"
          boxShadow="0px 7px 50px rgba(232, 242, 241, 0.8)"
        >
          <Box>
            <HStack align="flex-start" justify="space-between">
              <Box>
                <Heading fontSize="xl" color="teal.700" fontWeight="400">
                  Teaching Experience
                </Heading>
                <Box
                  mt={3}
                  width="80px"
                  h="3px"
                  backgroundColor="#4ECDC4"
                ></Box>
              </Box>
              <Text
                textDecoration="underline"
                color="#1A535C"
                onClick={() => removeAll()}
              >
                clear fields
              </Text>
            </HStack>
          </Box>

          <Stack spacing={6} py={4}>
            {teachingExperience.map((each, index) => (
              <>
                <Divider />
                <DeleteSection onClick={() => remove(index)} />

                <Input
                  py={6}
                  type="text"
                  name="title"
                  aria-label="Course Title"
                  placeholder="Course Title"
                  value={getter('title', index)}
                  onChange={handleInputChangeForIndex(index)}
                />
                <HStack>
                  <Input
                    py={6}
                    type="text"
                    name="code"
                    aria-label="Course Code"
                    placeholder="Course Code"
                    value={getter('code', index)}
                    onChange={handleInputChangeForIndex(index)}
                  />
                  <Input
                    py={6}
                    type="number"
                    aria-label="Course Units"
                    name="units"
                    placeholder="Course Units"
                    value={getter('units', index)}
                    onChange={handleInputChangeForIndex(index)}
                  />
                </HStack>
              </>
            ))}
            <Button
              py={6}
              px={6}
              fontWeight={400}
              onClick={addNew}
              variant="outline"
              colorScheme="teal"
              borderStyle="dashed"
            >
              <Icon as={IoIosAddCircleOutline} w={6} h={6} marginRight={3} />
              Add another Teaching Experience
            </Button>
          </Stack>
        </Box>
      </Flex>
    </>
  );
};

export default Teaching;
