import React from 'react';
import {
  Box,
  Flex,
  Link,
  Text,
  Input,
  Stack,
  Button,
  Heading,
  FormControl,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import Select from 'react-select';
import { useFormik } from 'formik';
import { useHistory } from 'react-router';

import { axiosFactory } from '../utils/axios';
import { usePostHook } from '../utils/request';

const CreateProfileForm = ({ toggle }) => {
  const { push } = useHistory();

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const { makeRequest } = usePostHook(axiosFactory(), '/user/register');
  const {
    handleSubmit,
    handleBlur,
    handleChange,
    handleReset,
    setFieldValue,
    values: formValues,
    isSubmitting,
  } = useFormik({
    initialValues: {
      dob: '',
      email: '',
      gender: '',
      phoneNo: '',
      password: '',
      lastname: '',
      firstname: '',
      department: '',
      faculty: '',
      middlename: '',
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        console.log({
          dob: values.dob,
          email: values.email,
          gender: values.gender,
          phoneNo: values.phoneNo,
          password: values.password,
          lastname: values.lastname,
          firstname: values.firstname,
          department: values.department,
          middlename: values.middlename,
        });
        const { data } = await makeRequest({
          dob: values.dob,
          email: values.email,
          gender: values.gender,
          phoneNo: values.phoneNo,
          password: values.password,
          lastname: values.lastname,
          firstname: values.firstname,
          department: values.department,
          middlename: values.middlename,
          faculty: values.faculty,
        });

        localStorage.setItem('token', data.data.token);
        localStorage.setItem('user_data', JSON.stringify(data.data));
        push('/dashboard');
      } catch (error) {
        console.error(error.message);
      }
    },
  });

  const options = [
    { value: 'M', label: ' Male' },
    { value: 'F', label: 'Female' },
  ];

  const facultyOptions = [{ value: 'Science', label: 'Science' }];
  const departmentOptions = [
    {
      value: 'Archaeology & Anthropology',
      label: 'Archaeology & Anthropology',
      faculty: 'sci',
    },
    { value: 'Botany', label: 'Botany', faculty: 'sci' },
    { value: 'Chemistry', label: 'Chemistry', faculty: 'sci' },
    { value: 'Computer Science', label: 'Computer Science', faculty: 'sci' },
    { value: 'Geography', label: 'Geography', faculty: 'sci' },
    { value: 'Geology', label: 'Geology', faculty: 'sci' },
    { value: 'Mathematics', label: 'Mathematics', faculty: 'sci' },
    { value: 'Microbiology', label: 'Microbiology', faculty: 'sci' },
    { value: 'Physics', label: 'Statistics', faculty: 'sci' },
    { value: 'Statistics', label: 'Statistics', faculty: 'sci' },
    { value: 'Zoology', label: 'Zoology', faculty: 'sci' },
  ];

  console.log(formValues);

  return (
    <Flex
      p={8}
      flex={1}
      align={'center'}
      justify={'center'}
      flexDirection="column"
    >
      <Stack spacing={4} w={'full'} maxW={'lg'}>
        <Heading fontSize={'1.875rem'} textAlign="center">
          Set Up Your Profile
        </Heading>
        <Text textAlign="center">
          To complete your registration, you’ll need a profile.
        </Text>

        <form
          onSubmit={handleSubmit}
          style={{
            flex: '1',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
          onReset={handleReset}
        >
          <Stack spacing={8}>
            <FormControl id="email" marginTop="2.5rem">
              <Input
                py={6}
                type="email"
                name="email"
                aria-label="Email address"
                placeholder="Email Address"
                onChange={handleChange}
                value={formValues.email}
              />
            </FormControl>
            <FormControl id="password">
              <InputGroup>
                <Input
                  py={6}
                  name="password"
                  aria-label="Password"
                  placeholder="Password"
                  autoComplete="new-password"
                  onChange={handleChange}
                  value={formValues.password}
                  type={show ? 'text' : 'password'}
                />
                <InputRightElement marginTop={1} width="4.5rem">
                  <Button
                    h="1.75rem"
                    background="none"
                    size="sm"
                    onClick={handleClick}
                  >
                    {show ? 'Hide' : 'Show'}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <FormControl id="firstName">
              <Input
                py={6}
                aria-label="First Name"
                placeholder="First Name"
                name="firstname"
                onChange={handleChange}
                value={formValues.firstname}
              />
            </FormControl>
            <Stack
              spacing={8}
              width="100%"
              marginTop={10}
              direction={{ base: 'column', md: 'row' }}
            >
              <FormControl id="lastName">
                <Input
                  py={6}
                  aria-label="Last Name"
                  placeholder="Last Name"
                  name="lastname"
                  onChange={handleChange}
                  value={formValues.lastname}
                />
              </FormControl>
              <FormControl id="middlename">
                <Input
                  py={6}
                  aria-label="Middle Name"
                  placeholder="Middle Name"
                  name="middlename"
                  onChange={handleChange}
                  value={formValues.middlename}
                />
              </FormControl>
            </Stack>
            <FormControl id="phoneNo">
              <Input
                py={6}
                type="number"
                placeholder="Phone Number"
                name="phoneNo"
                onChange={handleChange}
                value={formValues.phoneNo}
              />
            </FormControl>
            <Stack
              spacing={8}
              width="100%"
              direction={{ base: 'column', md: 'row' }}
            >
              <FormControl id="gender">
                <Select
                  options={options}
                  placeholder="Gender"
                  name="gender"
                  value={
                    options
                      ? options.find(
                          option => option.value === formValues.gender
                        )
                      : ''
                  }
                  onChange={option => setFieldValue('gender', option.value)}
                  onBlur={handleBlur}
                  styles={{
                    control: styles => ({
                      ...styles,
                      width: '100%',
                      height: '50px',
                      marginTop: '0',
                      overflow: 'hidden',
                    }),
                    dropdownIndicator: styles => ({
                      padding: '0.8rem',
                      color: 'white',
                      backgroundColor: '#267D77',
                    }),
                    container: styles => ({
                      ...styles,
                      padding: '0',
                      width: '100%',
                      height: '100%',
                    }),
                    option: styles => ({
                      ...styles,
                      paddingLeft: '1.25rem',
                    }),
                  }}
                />
              </FormControl>

              <FormControl id="dob">
                <Input
                  py={6}
                  name="dob"
                  type="date"
                  value={formValues.dob}
                  onChange={handleChange}
                  placeholder="Date of Birth"
                />
              </FormControl>
            </Stack>
            <Stack
              spacing={8}
              width="100%"
              direction={{ base: 'column', md: 'row' }}
            >
              <FormControl id="department">
                <Select
                  options={departmentOptions}
                  placeholder="Department"
                  name="department"
                  onBlur={handleBlur}
                  value={
                    departmentOptions
                      ? departmentOptions.find(
                          option => option.value === formValues.department
                        )
                      : ''
                  }
                  onChange={option => setFieldValue('department', option.value)}
                  styles={{
                    control: styles => ({
                      ...styles,
                      width: '100%',
                      height: '50px',
                      marginTop: '0',
                      overflow: 'hidden',
                    }),
                    dropdownIndicator: styles => ({
                      padding: '0.8rem',
                      color: 'white',
                      backgroundColor: '#267D77',
                    }),

                    container: styles => ({
                      ...styles,
                      padding: '0',
                      width: '100%',
                      height: '100%',
                    }),
                    option: styles => ({
                      ...styles,
                      paddingLeft: '1.25rem',
                    }),
                  }}
                />
              </FormControl>
              <FormControl id="faculty">
                <Select
                  options={facultyOptions}
                  placeholder="Faculty"
                  name="faculty"
                  onBlur={handleBlur}
                  value={
                    facultyOptions
                      ? facultyOptions.find(
                          option => option.value === formValues.faculty
                        )
                      : ''
                  }
                  onChange={option => setFieldValue('faculty', option.value)}
                  styles={{
                    control: styles => ({
                      ...styles,
                      width: '100%',
                      height: '50px',
                      marginTop: '0',
                      overflow: 'hidden',
                    }),
                    dropdownIndicator: styles => ({
                      padding: '0.8rem',
                      color: 'white',
                      backgroundColor: '#267D77',
                    }),

                    container: styles => ({
                      ...styles,
                      padding: '0',
                      width: '100%',
                      height: '100%',
                    }),
                    option: styles => ({
                      ...styles,
                      paddingLeft: '1.25rem',
                    }),
                  }}
                />
              </FormControl>
            </Stack>
          </Stack>

          <Box width="100%">
            <Button
              py={8}
              width="full"
              marginTop={8}
              type="submit"
              color="white"
              fontWeight="400"
              isLoading={isSubmitting}
              variant={'solid'}
              fontSize="1.125rem"
              colorScheme="green"
              fontFamily="Inter"
              backgroundColor="#267D77"
            >
              Register
            </Button>
          </Box>
        </form>
      </Stack>
      <Text marginTop="10%" color="#267D7799">
        Already have an account?{' '}
        <Link href="/login" color="#267D77">
          Login
        </Link>
      </Text>
    </Flex>
  );
};

export default CreateProfileForm;
