import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  Input,
  Stack,
  Text,
  Divider,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { MajorConfContext } from '../context';
import DeleteSection from '../preview/generic/delete';
import { IoIosAddCircleOutline } from 'react-icons/io';
import isEmpty from '../utils/isEmpty';
import { Field } from '../utils/special';

export const MajorConfPreview = () => {
  const { getter, majorConf } = useContext(MajorConfContext);
  return (
    <div className="cv-section">
      <div
        style={{
          display: 'flex',
        }}
      >
        <p>XI.</p>
        <div className="two-column" style={{ paddingLeft: '20px' }}>
          <p className="underline">
            <Field>Major Conferences Attended with Papers Read (in the last 5 years)</Field>
          </p>{' '}
          <p>{isEmpty(majorConf) && 'Nil'}</p>
        </div>
      </div>
      <ol className="lower-roman">
        {!isEmpty(majorConf) &&
          majorConf.map((each, index) => (
            <li
              key={index}
              style={{
                marginLeft: '34px',
              }}
            >
              <p> </p>
              <div className="two-column" style={{ marginLeft: '4px' }}>
                <p>
                  <span>{getter('details', index)}</span>,{' '}
                  <span>{getter('paper', index)}</span>
                </p>{' '}
              </div>
            </li>
          ))}
      </ol>
    </div>
  );
};

const MajorConf = () => {
  const { getter, setter, majorConf, addNew, remove, removeAll } =
    useContext(MajorConfContext);

  const handleInputChangeForIndex = index => e => {
    setter(e.target.name, e.target.value, index);
  };

  return (
    <>
      <Flex
        mb={8}
        px={12}
        width="50%"
        height="100%"
        align="center"
        maxWidth="500px"
        direction="column"
        justify="space-between"
      >
        <Box
          p={6}
          mb={10}
          width="530px"
          boxShadow="0px 7px 50px rgba(232, 242, 241, 0.8)"
        >
          <Box>
            <HStack align="flex-start" justify="space-between">
              <Box>
                <Heading fontSize="xl" color="teal.700" fontWeight="400">
                  Major Conferences Attended with Papers Read (in the last 5
                  years)
                </Heading>
                <Box
                  mt={3}
                  width="80px"
                  h="3px"
                  backgroundColor="#4ECDC4"
                ></Box>
              </Box>
              <Text
                textDecoration="underline"
                color="#1A535C"
                onClick={() => removeAll()}
              >
                clear fields
              </Text>
            </HStack>
          </Box>
          <Stack>
            {majorConf.map((each, index) => (
              <Stack key={index} spacing={6} py={4}>
                <Divider />
                <DeleteSection onClick={() => remove(index)} />
                <Input
                  py={6}
                  type="text"
                  name="details"
                  aria-label="Details"
                  value={getter('details', index)}
                  onChange={handleInputChangeForIndex(index)}
                  placeholder="Details"
                />
                <Input
                  py={6}
                  type="text"
                  name="paper"
                  aria-label="Paper Read"
                  value={getter('paper', index)}
                  onChange={handleInputChangeForIndex(index)}
                  placeholder="Paper Read"
                />
                <Input
                  py={6}
                  type="text"
                  name="publication_url"
                  aria-label="Link to Publication Online"
                  placeholder="Link to Publication Online"
                  value={getter('publication_url', index)}
                  onChange={handleInputChangeForIndex(index)}
                />
              </Stack>
            ))}
            <Button
              py={6}
              px={6}
              disabled={majorConf.length > 5 ? true : false}
              fontWeight={400}
              onClick={addNew}
              variant="outline"
              colorScheme="teal"
              borderStyle="dashed"
            >
              <Icon as={IoIosAddCircleOutline} w={6} h={6} marginRight={3} />
              Add another Honour and Distinction
            </Button>
          </Stack>
        </Box>
      </Flex>
    </>
  );
};

export default MajorConf;
