import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Select,
  useToast,
} from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';
import CvHeader from '../../components/CvHeader';
import ReusablePreview from '../cv/resusable';
import { Editable, withReact, useSlate, Slate } from 'slate-react';
import {
  Editor,
  Transforms,
  createEditor,
  Descendant,
  Element as SlateElement,
} from 'slate';
import { withHistory } from 'slate-history';
import { useState } from 'react';
import { useCallback } from 'react';
import { useMemo } from 'react';
import { useGetHook, usePostHook } from '../../utils/request';
import { axiosFactory } from '../../utils/axios';
import { AuthGuard } from '../../utils/guard';

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const initialValue = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];
const AssessorSubmissionPage = () => {
  const { reviewId } = useParams();
  const history = useHistory();
  const [value, setValue] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(null);
  const { loading, data } = useGetHook(
    axiosFactory(),
    `/cv/assessor/assessor_review/${reviewId}/state`,
    {
      guards: [AuthGuard(history)],
    }
  );

  const toast = useToast();
  const {
    loading: loadingDetails,
    data: details,
    refetch,
  } = useGetHook(axiosFactory(), `/cv/assessor/assessor_review/${reviewId}`, {
    guards: [AuthGuard(history)],
  });

  const { loading: submitting, makeRequest } = usePostHook(
    axiosFactory(),
    `/cv/assessor/assessor_review/${reviewId}/submit`,
    {
      guards: [AuthGuard(history)],
    }
  );
  const handleSubmission = async () => {
    await makeRequest({
      comment: value,
    });
    await refetch();
    toast({
      title: 'Submmitted Review',
      description: '',
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  return (
    <>
      <CvHeader />
      <Grid templateColumns={'1fr 1fr'} sx={{ marginTop: '30px' }}>
        <ReusablePreview
          loading={loading}
          previewState={
            data && data.data.state ? JSON.parse(data.data.state) : null
          }
        />
        <Flex
          flexDirection={'column'}
          sx={{ marginTop: '20px' }}
          width={'600px'}
        >
          <Box>
            <Heading fontSize="xl" color="teal.700" fontWeight="400">
              Assessor Comments
            </Heading>
            <Box mt={3} width="80px" h="3px" backgroundColor="#4ECDC4"></Box>
          </Box>
          <Box
            sx={{
              backgroudColor: 'white',
              borderRadius: '20px',
              marginTop: '20px',
              height: '200px',
            }}
          >
            <textarea
              style={{
                width: '600px',
                height: '190px',
                border: '1px solid teal',
                backgroundColor: 'white',
                borderRadius: '10px',
                outline: 'none',
                padding: '10px',
              }}
              onChange={e => setValue(e.target.value)}
            ></textarea>
            {/* <Slate
              editor={editor}
              value={value}
              onChange={value => setValue(value)}
            >
              <Editable placeholder="Enter some plain text..." />
            </Slate> */}
          </Box>
          <Box>
            <Button
              onClick={handleSubmission}
              disabled={
                details && details.data.status.code === 'assessor.reviewed'
              }
              isLoading={submitting}
              colorScheme="teal"
              py={6}
              px={6}
            >
              {details && details.data.status.code === 'assessor.reviewed'
                ? 'Review Submitted'
                : 'Submit Review'}
            </Button>
          </Box>
        </Flex>
      </Grid>
    </>
  );
};

export default AssessorSubmissionPage;
