import { Box, Center, Link } from '@chakra-ui/layout';
import React from 'react';

const Footer = () => {
  return (
    <Box as="footer" backgroundColor="#1E625D" py={10}>
      <Center flexDirection="column">
        <Link
          href="https://sci.ui.edu.ng/compsciwelcome"
          isExternal
          color="white"
        >
          Production of the Computer Science Department, University of Ibadan.
        </Link>
      </Center>
    </Box>
  );
};

export default Footer;
