import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    heading: 'Inter',
    body: 'Inter',
  },
  
  components: {
    Input: {
      baseStyle: {
        field: {
          fontWeight: '400',
          border: '1px solid',
          borderRadius: '3px',
          borderColor: '#267D77',
          color: 'rgba(26, 83, 92, 1)',
          ':focus': {
            borderColor: '#',
            background: '#DEEAE960',
          },
        },
      },
      defaultProps: {
        variant: null,
      },
    },
    Button: {
      baseStyle: {
        fontWeight: '400',
        fontFamily: 'Inter',
      },
    },
  },
});

export default theme;

// theme.js
// import { extendTheme } from "@chakra-ui/react"

// const theme = extendTheme({
//   components: {
//     Button: {
//       // 1. We can update the base styles
//       baseStyle: {
//         fontWeight: '400', // Normally, it is "semibold"
//       },
//       // 2. We can add a new button size or extend existing
//       // sizes: {
//       //   xl: {
//       //     h: '56px',
//       //     fontSize: 'lg',
//       //     px: '32px',
//       //   },
//       // },
//       // // 3. We can add a new visual variant
//       // variants: {
//       //   'with-shadow': {
//       //     bg: 'red.400',
//       //     boxShadow: '0 0 2px 2px #efdfde',
//       //   },
//       //   // 4. We can override existing variants
//       //   solid: props => ({
//       //     bg: props.colorMode === 'dark' ? 'red.300' : 'red.500',
//       //   }),
//       // },
//     },
//   },
// });

// export default theme;
