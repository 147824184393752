import {
  Box,
  Flex,
  Icon,
  Text,
  Input,
  Stack,
  Button,
  HStack,
  Divider,
  Heading,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import DeleteSection from '../preview/generic/delete';
import isEmpty from '../utils/isEmpty';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { UniversityEducationContext } from '../context';
import { Field } from '../utils/special';

export const UniversityEducationPreview = () => {
  const { getter, universityEducation } = useContext(
    UniversityEducationContext
  );
  return (
    <div className="cv-section">
      <div
        style={{
          display: 'flex',
        }}
      >
        <p>III.</p>
        <div className="two-column" style={{ paddingLeft: '26px' }}>
          <p className="underline">
            <Field>University Education (with dates):</Field>{' '}
          </p>{' '}
          <p>{isEmpty(universityEducation) && 'Nil'}</p>
        </div>
      </div>
      <ol className="lower-roman">
        {!isEmpty(universityEducation) &&
          universityEducation.map((each, index) => (
            <li
              key={index}
              style={{
                marginLeft: '34px',
              }}
            >
              <p> </p>
              <div className="two-column" style={{ marginLeft: '4px' }}>
                <p>
                  <span>
                    {getter('university_name', index)},{' '}
                    {getter('country', index)}
                  </span>
                </p>{' '}
                <p>
                  <span>
                    {getter('period_start', index)} -{' '}
                    {getter('period_end', index)}{' '}
                  </span>{' '}
                </p>
              </div>
            </li>
          ))}
      </ol>
    </div>
  );
};

const UniversityEducation = () => {
  // eslint-disable-next-line
  const { getter, setter, universityEducation, addNew, removeAll, remove } =
    useContext(UniversityEducationContext);

  const handleInputChangeForIndex = index => e => {
    setter(e.target.name, e.target.value, index);
  };

  return (
    <>
      <Flex
        mb={8}
        px={12}
        width="50%"
        height="100%"
        align="center"
        maxWidth="500px"
        direction="column"
        justify="space-between"
      >
        <Box
          p={6}
          mb={10}
          width="530px"
          boxShadow="0px 7px 50px rgba(232, 242, 241, 0.8)"
        >
          <Box>
            <HStack align="flex-start" justify="space-between">
              <Box>
                <Heading fontSize="xl" color="teal.700" fontWeight="400">
                  University Education
                </Heading>
                <Box
                  mt={3}
                  width="80px"
                  h="3px"
                  backgroundColor="#4ECDC4"
                ></Box>
              </Box>
              <Text
                textDecoration="underline"
                color="#1A535C"
                onClick={() => removeAll()}
              >
                clear fields
              </Text>
            </HStack>
          </Box>

          {universityEducation.map((each, index) => (
            <>
              <Divider />
              <Stack spacing={6} py={4}>
                <DeleteSection onClick={() => remove(index)} />
                <HStack>
                  <Input
                    py={6}
                    type="text"
                    name="university_name"
                    aria-label="Email"
                    value={getter('university_name', index)}
                    onChange={handleInputChangeForIndex(index)}
                    placeholder="University Name"
                  />
                  <Input
                    py={6}
                    type="text"
                    name="degree"
                    value={getter('degree', index)}
                    onChange={handleInputChangeForIndex(index)}
                    placeholder="Degree"
                  />
                </HStack>
                <HStack>
                  <Input
                    py={6}
                    type="text"
                    value={getter('country', index)}
                    onChange={handleInputChangeForIndex(index)}
                    aria-label="Emai"
                    name="country"
                    placeholder="Country"
                  />
                  <Input
                    py={6}
                    name="city"
                    type="text"
                    value={getter('city', index)}
                    onChange={handleInputChangeForIndex(index)}
                    placeholder="City"
                  />
                </HStack>
                <HStack>
                  <Input
                    py={6}
                    step="1"
                    min="1900"
                    max="2099"
                    type="number"
                    name="period_start"
                    aria-label="Start Year"
                    placeholder="Start Year"
                    value={getter('period_start', index)}
                    onChange={handleInputChangeForIndex(index)}
                  />
                  <Input
                    py={6}
                    step="1"
                    min="1900"
                    max="2099"
                    type="number"
                    name="period_end"
                    placeholder="End Year"
                    value={getter('period_end', index)}
                    onChange={handleInputChangeForIndex(index)}
                  />
                </HStack>
              </Stack>
            </>
          ))}
          <Button
            py={6}
            px={6}
            fontWeight={400}
            onClick={addNew}
            isFullWidth
            variant="outline"
            colorScheme="teal"
            borderStyle="dashed"
          >
            <Icon as={IoIosAddCircleOutline} w={6} h={6} marginRight={3} />
            Add another University
          </Button>
        </Box>
      </Flex>
    </>
  );
};

export default UniversityEducation;
