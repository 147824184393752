const isEmpty = arr => {
  if (Array.isArray(arr)) {
    if (arr.length === 1) {
      return JSON.stringify(arr[0]) === '{}' ? true : false;
    } else if (arr.length > 1) {
      return false;
    }

    return false;
  }
};

export default isEmpty;
