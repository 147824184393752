import { useHistory } from 'react-router';
import { useContext, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { Box, Button, HStack, useToast, VStack } from '@chakra-ui/react';
import {
  FiDownloadCloud,
  FiPrinter,
  FiUploadCloud,
  FiArrowLeft,
  FiArrowRight,
} from 'react-icons/fi';

import { AuthGuard } from '../../../utils/guard';
import { axiosFactory } from '../../../utils/axios';
import { usePutHook } from '../../../utils/request';

import { BooksPreview } from '../forms/Books';
import { DownloadElementById } from '../utils';
import { FullScreenContext, PositionSoughtContext } from '../context';
import { ArticlePreview } from '../forms/Articles';
import { ScholarshipPreview } from '../forms/Scholarship';
import { BookChaptersPreview } from '../forms/BookChapters';
import { ExperiencePreview } from '../forms/WorkExperience';
import { MajorConfPreview } from '../forms/MajorConferences';
import { ResearchFocusPreview } from '../forms/ResarchFocus';
import { AcceptedPublications } from '../forms/BooksAccepted';
import { TechnicalMonographsPreview } from '../forms/Technical';
import { PersonalDetailsPreview } from '../forms/PersonalDetails';
import { ConferencePreview } from '../forms/ConferenceProceedings';
import { PatentCopyrightsPreview } from '../forms/PatentCopyrights';
import { HonourDistinctionPreview } from '../forms/HonourDistinction';
import { ProfessionalQualificationsPreview } from '../forms/Professional';
import { UniversityEducationPreview } from '../forms/UniversityEducation';
import { AcademicQualificationPreview } from '../forms/AcademicQualifications';
import { Field } from '../utils/special';

const PreviewComponent = ({ withSubmission, noDownload }) => {
  const history = useHistory();
  const toast = useToast();

  const handleDownloadClick = async e => {
    await DownloadElementById('PreviewContainer');
  };

  const { loading, makeRequest: submitCV } = usePutHook(
    axiosFactory(),
    '/cv/submit',
    {
      guards: [AuthGuard(history)],
    }
  );

  const { getter: getPositionSought } = useContext(PositionSoughtContext);

  const handleSubmission = async () => {
    await submitCV();
    toast({
      title: 'Submitted CV',
      description: 'You can track your CV review status from your dashboard',
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };
  const { toggleShowFullscreen, nextStep, prevStep, currentStep } =
    useContext(FullScreenContext);

  return (
    <VStack
      top={20}
      spacing={6}
      minH="700px"
      height="fit-content"
      position="sticky"
    >
      <Box
        padding={6}
        width="70%"
        minHeight="700px"
        fontFamily="serif"
        lineHeight="1.5"
        height="fit-content"
        fontSize="8px"
        flex="1 0 auto"
        border="1px solid #267D77"
        boxShadow="0px 8px 30px rgba(232, 242, 241, 0.8)"
      >
        <div
          style={{
            top: '0',
            width: '100%',
            display: 'flex',
            height: '660px',
            padding: '10px',
            fontFamily: '"Times New Roman" !important',
            overflow: 'scroll',
            position: 'relative',
            flexDirection: 'column',
          }}
          id="PreviewContainer"
        >
          <h2
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: '14px',
            }}
          >
            2021 PROMOTION EXCERCISE
          </h2>
          <h2
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: '14px',
            }}
          >
            {' '}
            POSITION SOUGHT:{' '}
            {getPositionSought('position_sought').toUpperCase()}
          </h2>
          <h2
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: '14px',
            }}
          >
            CURRICULUM VITAE
          </h2>
          <PersonalDetailsPreview />

          <UniversityEducationPreview />

          <AcademicQualificationPreview />

          <ProfessionalQualificationsPreview />
          <ScholarshipPreview />

          <HonourDistinctionPreview />

          <ExperiencePreview />

          <div className="cv-section">
            <div
              style={{
                display: 'flex',
              }}
            >
              <p>X.</p>
              <div className="two-column" style={{ paddingLeft: '18px' }}>
                <p className="underline">
                  <Field>Publications:</Field>
                </p>{' '}
                <p></p>
              </div>
            </div>
            <ol className="publication-list">
              <BooksPreview />
              <BookChaptersPreview />
              <ConferencePreview />
              <PatentCopyrightsPreview />
              <ArticlePreview />
              <AcceptedPublications />
              <TechnicalMonographsPreview />
            </ol>
          </div>

          <ResearchFocusPreview />

          <MajorConfPreview />

          {/* <EditorialWorkPreview /> */}
        </div>
      </Box>

      <VStack width="70%" direction="row" justify="space-between">
        <Button
          p={6}
          isFullWidth
          onClick={() => toggleShowFullscreen()}
          variant="outline"
          colorScheme="teal"
          borderRadius="none"
          leftIcon={<FiPrinter />}
        >
          Preview FullScreen
        </Button>
        <HStack width={'100%'}>
          <Button
            isFullWidth
            variant="solid"
            colorScheme="teal"
            borderRadius="none"
            onClick={prevStep}
            leftIcon={<FiArrowLeft />}
          >
            Previous step
          </Button>
          <Button
            isFullWidth
            variant="solid"
            onClick={currentStep === 19 ? handleSubmission : nextStep}
            colorScheme="teal"
            isLoading={loading}
            borderRadius="none"
            rightIcon={<FiArrowRight />}
          >
            {currentStep === 19 ? 'Submit For Review' : 'Save and Continue'}
          </Button>
        </HStack>
        {withSubmission && (
          <Button
            p={6}
            isFullWidth
            variant="solid"
            colorScheme="teal"
            isLoading={loading}
            borderRadius="none"
            // onClick={handleSubmission}
            rightIcon={<FiUploadCloud />}
          >
            Save and Continue
          </Button>
        )}
      </VStack>
    </VStack>
  );
};

export const FullscreenPreview = ({ onClickAway, open, setOpen }) => {
  const [downloadMode, setDownloadMode] = useState(false);
  const { toggleShowFullscreen } = useContext(FullScreenContext);
  const handleDownloadClick = async e => {
    e.stopPropagation();
    setDownloadMode(true);
    window.scrollTo(0, 0);
    await DownloadElementById('FullPreview');
    setDownloadMode(false);
  };

  const { getter: getPositionSought } = useContext(PositionSoughtContext);

  return (
    <div
      style={{
        position: 'fixed',
        top: '0',
        left: '0',
        filter: 'blur:(20px)',
        display: 'flex',
        width: '100%',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: '30px',
        overflow: 'scroll',
        fontFamily: '"Times New Roman" !important',
        backgroundColor: 'rgba(128, 128, 128, 0.529)',
        zIndex: '1000',
      }}
    >
      <ClickAwayListener
        style={{ backdropFilter: 'blur(10px)' }}
        onClickAway={() => toggleShowFullscreen()}
      >
        <div
          style={{
            top: '0',
            left: '0',
            backgroundColor: 'white',
            width: '700px',
            backdropFilter: 'blur(10px)',
            minHeight: '700px',
            position: 'relative',
            fontFamily: '"Times New Roman"',
            padding: '25px',
            fontSize: '12px',
          }}
          id="FullPreview"
        >
          <h2
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: '14px',
            }}
          >
            {' '}
            2021 PROMOTION EXCERCISE
          </h2>
          <h2
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: '14px',
            }}
          >
            {' '}
            POSITION SOUGHT:{' '}
            {getPositionSought('position_sought').toUpperCase()}
          </h2>
          <h2
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: '14px',
            }}
          >
            {' '}
            CURRICULUM VITAE
          </h2>
          <PersonalDetailsPreview />

          <UniversityEducationPreview />

          <AcademicQualificationPreview />

          <ProfessionalQualificationsPreview />
          <ScholarshipPreview />

          <HonourDistinctionPreview />

          <ExperiencePreview />

          {/* <TechnicalPreview />
          <RevisedChaptersPreview /> */}

          <div className="cv-section break-avoid">
            <div
              style={{
                display: 'flex',
                paddingTop: '40px',
              }}
            >
              <p>X.</p>
              <div className="two-column" style={{ paddingLeft: '18px' }}>
                <p className="underline">Publications:</p> <p></p>
              </div>
            </div>
            <ol className="publication-list">
              <BooksPreview />
              <BookChaptersPreview />
              <ConferencePreview />
              <PatentCopyrightsPreview />
              <ArticlePreview />
              <AcceptedPublications />
              <TechnicalMonographsPreview />
            </ol>
          </div>

          <ResearchFocusPreview
            style={{
              padding: '20px 35px',
            }}
          />

          <MajorConfPreview />
        </div>
      </ClickAwayListener>
      <div
        style={{
          display: 'flex',
          position: 'fixed',
          bottom: '20px',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <Button
          variant="solid"
          onClick={handleDownloadClick}
          colorScheme="teal"
          borderRadius="none"
          isLoading={downloadMode}
          rightIcon={<FiDownloadCloud />}
        >
          Download
        </Button>
      </div>
    </div>
  );
};

export default PreviewComponent;
