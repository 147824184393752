import { Box, Heading, HStack, Input, Stack } from '@chakra-ui/react';
import { useContext } from 'react';
import DateInput from '../../../components/DateInput';
import { PersonalDetailsContext, PositionSoughtContext } from '../context';
import { getOnChangeHandlerFromSetter } from '../utils';
import { Field } from '../utils/special';

export const PersonalDetailsPreview = () => {
  const { getter: getPersonalDetails } = useContext(PersonalDetailsContext);

  return (
    <>
      <div className="cv-section">
        <div
          style={{
            display: 'flex',
          }}
        >
          <p>I.</p>
          <div className="two-column" style={{ paddingLeft: '30px' }}>
            <p>
              (a) <Field> Name:</Field>
            </p>{' '}
            <p>
              {`  ${getPersonalDetails('firstname')} ${getPersonalDetails(
                'othernames'
              )} `}
              <span className="bold-and-underline">
                <Field>{getPersonalDetails('surname')}</Field>
              </span>
            </p>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            marginLeft: '4px',
          }}
        >
          <p> </p>
          <div className="two-column" style={{ paddingLeft: '30px' }}>
            <p>
              (b) <Field>Date of Birth: </Field>
            </p>{' '}
            <p>
              <span>{getPersonalDetails('dob')}</span>
            </p>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            marginLeft: '4px',
          }}
        >
          <p> </p>
          <div className="two-column" style={{ paddingLeft: '30px' }}>
            <p>
              (c) <Field>Department: </Field>
            </p>{' '}
            <p>
              <span>{getPersonalDetails('department')}</span>
            </p>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            marginLeft: '4px',
          }}
        >
          <p> </p>
          <div className="two-column" style={{ paddingLeft: '30px' }}>
            <p>
              (d) <Field>Faculty: </Field>
            </p>{' '}
            <p>
              <span>{getPersonalDetails('faculty')}</span>
            </p>
          </div>
        </div>
      </div>

      <div className="cv-section">
        <div
          style={{
            display: 'flex',
          }}
        >
          <p>II.</p>
          <div
            className="two-column"
            style={{ paddingLeft: '30px', marginLeft: '-2px' }}
          >
            <p>
              (a) <Field>First Academic Appointment: </Field>{' '}
            </p>{' '}
            <p>
              <span>{getPersonalDetails('firstAcademicAppointment')} </span>
            </p>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            marginLeft: '4px',
          }}
        >
          <p> </p>
          <div className="two-column" style={{ paddingLeft: '30px' }}>
            <p>
              (b) <Field>Present Post (with date):</Field>
            </p>{' '}
            <p>
              <span>{getPersonalDetails('presentPost')}</span>
            </p>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            marginLeft: '4px',
          }}
        >
          <p> </p>
          <div className="two-column" style={{ paddingLeft: '30px' }}>
            <p>
              (c) <Field>Date of Last Promotion:</Field>{' '}
            </p>{' '}
            <p>
              <span>{getPersonalDetails('dateOfLastPromotion')}</span>
            </p>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            marginLeft: '4px',
          }}
        >
          <p> </p>
          <div className="two-column" style={{ paddingLeft: '30px' }}>
            <p>
              (d) <Field>Date Last Considered</Field>
            </p>
            <p></p>
            <p style={{ marginLeft: '12px' }}>
              (in cases where promotion was not through):
            </p>
            <p>
              <span>{getPersonalDetails('dateOfLastPromotionTrial')}</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

const PersonalDetails = () => {
  const { getter: getPersonalDetails, setter } = useContext(
    PersonalDetailsContext
  );

  const { setter: setPositionSought, getter: getPositionSought } = useContext(PositionSoughtContext);
  //   generate an onChange handler from the personalDetails setter
  const onInputChange = getOnChangeHandlerFromSetter(setter);
  const onPositionSoughtChange = getOnChangeHandlerFromSetter(setPositionSought);

  return (
    <>
      <Box
        width="530px"
        p={6}
        mb={10}
        boxShadow="0px 7px 50px rgba(232, 242, 241, 0.8)"
      >
        <Box>
          <HStack align="flex-start" justify="space-between">
            <Box>
              <Heading fontSize="xl" color="teal.700" fontWeight="400">
                Personal Details
              </Heading>
              <Box mt={3} width="80px" h="3px" backgroundColor="#4ECDC4"></Box>
            </Box>
          </HStack>
        </Box>

        <Stack spacing={6} py={4}>
          <Input
            py={6}
            name="surname"
            type="text"
            value={getPersonalDetails('surname')}
            onChange={onInputChange}
            placeholder="Surname"
            aria-label="Surname"
          />
          <Input
            py={6}
            onChange={onInputChange}
            name="firstname"
            type="text"
            value={getPersonalDetails('firstname')}
            placeholder="First Name"
            aria-label="First Name"
          />
          <Input
            py={6}
            onChange={onInputChange}
            value={getPersonalDetails('othernames')}
            name="othernames"
            type="text"
            placeholder="Other Names"
            aria-label="Other Names"
          />
          <Input
            py={6}
            onChange={onPositionSoughtChange}
            value={getPositionSought('position_sought')}
            name="position_sought"
            type="text"
            placeholder="Position Sought"
            aria-label="Position Sought"
          />
          <HStack>
            <Input
              py={6}
              onChange={onInputChange}
              value={getPersonalDetails('initials')}
              name="initials"
              type="text"
              placeholder="Initials"
              aria-label="Initials"
            />
            <Input
              py={6}
              type="text"
              value={getPersonalDetails('dob')}
              name="dob"
              onChange={onInputChange}
              aria-label="Date of Birth"
              placeholder="Date of Birth"
            />
          </HStack>
          <Input
            py={6}
            type="text"
            value={getPersonalDetails('sex')}
            name="sex"
            onChange={onInputChange}
            placeholder="Sex (M/F)"
            aria-label="Sex (M/F)"
          />
          <Input
            py={6}
            type="text"
            name="department"
            value={getPersonalDetails('department')}
            onChange={onInputChange}
            placeholder="Department"
            aria-label="Department"
          />
          <Input
            py={6}
            name="faculty"
            value={getPersonalDetails('faculty')}
            onChange={onInputChange}
            type="text"
            placeholder="Faculty"
            aria-label="Faculty"
          />
        </Stack>
      </Box>

      <Box
        p={6}
        mb={10}
        width="530px"
        boxShadow="0px 7px 50px rgba(232, 242, 241, 0.8)"
      >
        <Box>
          <HStack align="flex-start" justify="space-between">
            <Box>
              <Heading fontSize="xl" color="teal.700" fontWeight="400">
                Academic Details
              </Heading>
              <Box mt={3} width="80px" h="3px" backgroundColor="#4ECDC4"></Box>
            </Box>
          </HStack>
        </Box>

        <Stack spacing={6} py={4}>
          <Input
            py={6}
            type="text"
            aria-label="First Academic Appointment"
            name="firstAcademicAppointment"
            value={getPersonalDetails('firstAcademicAppointment')}
            placeholder="First Academic Appointment (e.g. Lecturer 1, Lecturer II)."
            onChange={onInputChange}
          />
          <Input
            py={6}
            type="text"
            onChange={onInputChange}
            name="dateOfFirstAcademicAppointment"
            value={getPersonalDetails('dateOfFirstAcademicAppointment')}
            aria-label="Date of First Academic Appointment"
            placeholder="Date of First Academic Appointment"
          />
          <Input
            py={6}
            type="text"
            name="presentPost"
            aria-label="Present Post"
            placeholder="Present Post"
            value={getPersonalDetails('presentPost')}
            onChange={onInputChange}
          />
          <DateInput
            py={6}
            type="date"
            aria-label="Date appointed to post"
            name="dateAppointedToPost"
            value={getPersonalDetails('dateAppointedToPost')}
            placeholder="Date appointed to post"
            onChange={onInputChange}
          />
          <DateInput
            py={6}
            type="date"
            aria-label="Date of last promotion"
            placeholder="Date of last promotion"
            name="dateOfLastPromotion"
            value={getPersonalDetails('dateOfLastPromotion')}
            onChange={onInputChange}
          />

          <Input
            py={6}
            type="month"
            onChange={onInputChange}
            name="dateOfLastPromotionTrial"
            value={getPersonalDetails('dateOfLastPromotionTrial')}
            aria-label="Date of last promotion trial"
            placeholder="Date of last promotion trial"
          />
        </Stack>
      </Box>
    </>
  );
};

export default PersonalDetails;
