import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  Input,
  Stack,
  Divider,
  Text,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { RevisedChaptersContext } from '../context';
import DeleteSection from '../preview/generic/delete';
import SectionHeader from '../preview/generic/SectionHeader';

export const RevisedChaptersPreview = () => {
  const { getter, revisedChapters } = useContext(RevisedChaptersContext);

  return (
    <div>
      <SectionHeader value="Revised Chapters" />
      {revisedChapters.map((each, index) => (
        <div style={{ margin: '10px 20px' }}>
          <div>
            {' '}
            Authors: <b>{getter('revised_authors', index)}</b>
          </div>
          <div>
            {' '}
            Year: <b>{getter('revised_year', index)}</b>
          </div>
          <div>
            {' '}
            Title: <b>{getter('revised_title', index)}</b>
          </div>
          <div>
            {' '}
            Editors: <b>{getter('revised_editors', index)}</b>
          </div>
          <div>
            {' '}
            Revised Book Title: <b>{getter('revised_book_title', index)}</b>
          </div>
          <div>
            {' '}
            City: <b>{getter('revised_city', index)}</b>
          </div>
          <div>
            {' '}
            Publisher: <b>{getter('revised_publisher', index)}</b>
          </div>
          <div>
            {' '}
            Other Information: <b>{getter('revised_other', index)}</b>
          </div>
          <div>
            {' '}
            Country: <b>{getter('revised_country', index)}</b>
          </div>

          <div>
            {' '}
            Contribution (%): <b>{getter('revised_contribution', index)}</b>
          </div>
          <div>
            {' '}
            Status: <b>{getter('revised_status', index)}</b>
          </div>
        </div>
      ))}
    </div>
  );
};

const RevisedChapters = () => {
  const { getter, setter, revisedChapters, addNew, remove, removeAll } =
    useContext(RevisedChaptersContext);

  const handleInputChangeForIndex = index => e => {
    setter(e.target.name, e.target.value, index);
  };

  return (
    <>
      <Flex
        mb={8}
        px={12}
        width="50%"
        height="100%"
        align="center"
        maxWidth="500px"
        direction="column"
        justify="space-between"
      >
        <Box
          p={6}
          mb={10}
          width="530px"
          boxShadow="0px 7px 50px rgba(232, 242, 241, 0.8)"
        >
          <Box>
            <HStack align="flex-start" justify="space-between">
              <Box>
                <Heading fontSize="xl" color="teal.700" fontWeight="400">
                  Revised Chapters
                </Heading>
                <Box
                  mt={3}
                  width="80px"
                  h="3px"
                  backgroundColor="#4ECDC4"
                ></Box>
              </Box>
              <Text
                textDecoration="underline"
                color="#1A535C"
                onClick={() => removeAll({})}
              >
                clear fields
              </Text>
            </HStack>
          </Box>

          <Stack spacing={6} py={4}>
            {revisedChapters.map((each, index) => (
              <>
                <Divider />
                <DeleteSection onClick={() => remove(index)} />
                <Input
                  py={6}
                  name="revised_authors"
                  type="text"
                  aria-label="Revised Authors)"
                  value={getter('revised_authors', index)}
                  onChange={handleInputChangeForIndex(index)}
                  placeholder="Revised Authors"
                />{' '}
                <Input
                  py={6}
                  name="revised_title"
                  type="text"
                  aria-label="Revised Title"
                  value={getter('revised_title', index)}
                  onChange={handleInputChangeForIndex(index)}
                  placeholder="Revised Title"
                />
                <HStack>
                  <Input
                    py={6}
                    name="revised_year"
                    type="text"
                    aria-label="Revised Year"
                    value={getter('revised_year', index)}
                    onChange={handleInputChangeForIndex(index)}
                    placeholder="Revised Year"
                  />
                </HStack>
                <Input
                  py={6}
                  name="revised_editors"
                  type="text"
                  aria-label="Editors"
                  value={getter('revised_editors', index)}
                  onChange={handleInputChangeForIndex(index)}
                  placeholder="Editors"
                />{' '}
                <Input
                  py={6}
                  name="revised_book_title"
                  type="text"
                  aria-label="Book Title"
                  value={getter('revised_book_title', index)}
                  onChange={handleInputChangeForIndex(index)}
                  placeholder="Book Title"
                />
                <HStack>
                  <Input
                    py={6}
                    name="revised_city"
                    type="text"
                    aria-label="Revised City"
                    value={getter('revised_city', index)}
                    onChange={handleInputChangeForIndex(index)}
                    placeholder="Revised City"
                  />
                  <Input
                    py={6}
                    name="revised_publisher"
                    type="text"
                    aria-label="Revised Publisher"
                    value={getter('revised_publisher', index)}
                    onChange={handleInputChangeForIndex(index)}
                    placeholder="Revised Publisher"
                  />
                </HStack>
                <HStack>
                  <Input
                    py={6}
                    name="revised_country"
                    type="text"
                    aria-label="Country"
                    value={getter('revised_country', index)}
                    onChange={handleInputChangeForIndex(index)}
                    placeholder="Country"
                  />
                  <Input
                    py={6}
                    name="revised_contribution"
                    type="text"
                    aria-label="Contribution"
                    value={getter('revised_contribution', index)}
                    onChange={handleInputChangeForIndex(index)}
                    placeholder="Contribution"
                  />
                </HStack>
                <Input
                  py={6}
                  name="revised_other"
                  type="text"
                  aria-label="Others"
                  value={getter('revised_other', index)}
                  onChange={handleInputChangeForIndex(index)}
                  placeholder="Others"
                />
                <Input
                  py={6}
                  name="revised_status"
                  type="text"
                  aria-label="Status"
                  value={getter('revised_status', index)}
                  onChange={handleInputChangeForIndex(index)}
                  placeholder="Status"
                />
                <Input
                  py={6}
                  type="text"
                  name="publication_url"
                  aria-label="Link to Publication Online"
                  placeholder="Link to Publication Online"
                  value={getter('publication_url', index)}
                  onChange={handleInputChangeForIndex(index)}
                />
              </>
            ))}
            <Button
              py={6}
              px={6}
              fontWeight={400}
              onClick={addNew}
              variant="outline"
              colorScheme="teal"
              borderStyle="dashed"
            >
              <Icon as={IoIosAddCircleOutline} w={6} h={6} marginRight={3} />
              Add another Revised Chapters
            </Button>
          </Stack>
        </Box>
      </Flex>
    </>
  );
};

export default RevisedChapters;
