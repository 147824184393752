const findIndex = authors => {
  const formattedAuthors = authors
    .replaceAll(' and', ',')
    .split(',')
    .reduce((acc, val, idx) =>
      idx % 2 === 0 ? (acc ? `${acc} ${val}` : `${val}`) : `${acc},${val}`
    )
    .split('  ');

  return formattedAuthors;
};

export default findIndex;
